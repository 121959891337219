import styled from 'styled-components'
import ButtonBack from '../atoms/ButtonBack'
import qrImage from '../../assets/mockQR.png'

const LoginQR = props => {
  const { prevLoginStep } = props

  return (
    <Wrapper className='login-grid'>
      <img src={qrImage} alt='QRCode' className='qrlogin-image' />
      <ButtonBack
        buttonValue='Back'
        buttonOnClick={prevLoginStep}
        justifyCenter={true}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  grid-row-gap: 6px;
  grid-column-gap: 30px;
  margin: 6px 0px 20px 0px;
  align-items: center;
  justify-items: end;
  height: max-content;

  .qrlogin-image {
    grid-column: 1/-1;
    justify-self: center;
  }
`
export default LoginQR
