const listWholesaleData = {
  listHeaders: [
    {
      name: 'Suppl SKU WHS',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    { name: 'EAN', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Batch nr', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Sup.nr', width: 200, dropdown: false, reservedSorting: false },
    {
      name: 'Name search',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: '{product, name}',
      width: 400,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Range Identifier',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Supplier size code',
      width: 200,
      dropdown: true,
      reservedSorting: false
    },
    { name: 'Plant group', width: 200, dropdown: true, reservedSorting: false },
    { name: 'Stck', width: 100, dropdown: false, reservedSorting: false },
    { name: 'Ord.m', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Ord.i', width: 200, dropdown: false, reservedSorting: false },
    { name: 'PRCP', width: 100, dropdown: false, reservedSorting: false },
    { name: 'Curr', width: 100, dropdown: false, reservedSorting: false },
    { name: 'Tax', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Img 1', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Img 2', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Img 3', width: 200, dropdown: false, reservedSorting: false }
  ],
  listRecords: [
    {
      status: 210,
      sku: 'PM000138723',
      batchNumber: '2783005',
      btch_manufacturer: 2493,
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'BALLC5',
      sppl_size_code: '030C5BOL',
      sppl_stock_available: 250,
      sppl_prcp: 8.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 29,
      imageCore:
        'K42PSZCK;951D7L1S;8KGEPCXY;CKTG3T2E;924ZH6C1;B17T6X77;7GPKDHCV;VXRHH2T8;LZBECA7N;D641DBTR;671A6J8S;WSS5TCZ1;491D2KBB;BHXG53VB;C89WCS3P;6ZK6YB21;JVTSYWB5;534RWCNT;LBXEBVX1;YCTC95CG;VLC84L47;EBHD27X4;SCBDJ99Z;8GDWJBTD;6BKWASSZ;5PJ92JXA;CLP5DER8;N4GHHEN2;P6PASVWZ',
      statusContent: 'dot',
      hash: '5Y7PC9HL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Y7PC9HL'
    },
    {
      status: 210,
      sku: 'PM000138724',
      batchNumber: '6318843',
      btch_manufacturer: 43,
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      rng_range_identifier: 'H100110RB',
      sppl_size_code: '100110KL',
      sppl_stock_available: 150,
      sppl_prcp: 9.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 8,
      imageCore:
        'XJ4NZ82P;C67YPY19;KRV86KYN;WBWVEGYY;YKE3XH9C;7VBE7DD8;6C4B1695;4YPZPLA9',
      statusContent: 'dot',
      hash: 'GG9STWR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GG9STWR9'
    },
    {
      status: 990,
      sku: 'PM000010304',
      batchNumber: '5825892',
      btch_manufacturer: 5,
      name_search: 'GLTINERM',
      name: 'Gleditsia triacanthos inermis',
      rng_range_identifier: 'SPECH150200C20',
      sppl_size_code: '150200C20SO',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AP25YS6W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AP25YS6W'
    },
    {
      status: 210,
      sku: 'PM000138725',
      batchNumber: '6318890',
      btch_manufacturer: 975,
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      rng_range_identifier: 'STEM050C3',
      sppl_size_code: '050STC3',
      sppl_stock_available: 1439,
      sppl_prcp: 8.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 23,
      imageCore:
        '5ZBLWSJG;DJSHJ4YS;NJBTCYVA;946LDVKT;XGY2844X;7JYGD269;TX293S8W;8TYEVT86;5H6NN2TD;GC3T146X;49Z4Y39T;X5VAB4LS;AB52813Y;HR6D7ZDG;BP4LVX1H;7SV482P2;XT4E8PB2;JDNV29RB;1STESZLZ;ASHVR4JR;NP6GG2P6;7Z7EDYC7;6S48C5S4',
      statusContent: 'dot',
      hash: 'TRRP3VLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRRP3VLC'
    },
    {
      status: 210,
      sku: 'PM000138726',
      batchNumber: '6318891',
      btch_manufacturer: 975,
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      rng_range_identifier: 'STEM050C3',
      sppl_size_code: '050STC3',
      sppl_stock_available: 1598,
      sppl_prcp: 8.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 19,
      imageCore:
        '4H9RGJK6;2A6ZLWVS;6SN3NW9Y;L3B6PLLD;G1W167HG;7WCXZPBH;B4CJ5YNX;ELLGG4G3;A4H6CRPP;87Y9YHNS;JPGXE14D;DSD5EAGD;5AKE22BT;RZ2KCWLV;Z3E8P5G9;1XSABYAA;LNGVT4YN;WJCNC5ZW;9P3KT5VX',
      statusContent: 'dot',
      hash: 'T616PYSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T616PYSB'
    },
    {
      status: 990,
      sku: 'PM000010310',
      batchNumber: '5825898',
      btch_manufacturer: 5,
      name_search: 'MARSENTI',
      name: "Malus 'Red Sentinel'",
      rng_range_identifier: 'SPECH150175C20',
      sppl_size_code: '150175C20SO',
      sppl_stock_available: 0,
      sppl_prcp: 50.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 8,
      imageCore:
        '7NXEGNR5;6LJ3E9JZ;TV8STC5P;H64LGET2;K6GKV6KG;ZHJ9KX69;LHT9XJJH;P7ZDDRSJ',
      statusContent: 'dot',
      hash: 'K2SPKKGY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K2SPKKGY'
    },
    {
      status: 210,
      sku: 'PM000138727',
      batchNumber: '6318892',
      btch_manufacturer: 975,
      name_search: 'EUFHARLE',
      name: "Euonymus fortunei 'Harlequin'",
      rng_range_identifier: 'STEM050C3',
      sppl_size_code: '050STC3',
      sppl_stock_available: 445,
      sppl_prcp: 8.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 16,
      imageCore:
        'TLDWESXW;YXKD452G;9YGKZ49Y;E9VXT2TV;VZZ82DXE;1BRW84SR;RWX4J24B;8KJWHWJJ;77L1DY24;1WD3ERC1;248YB1RC;GZRSY5VT;B68XLRDA;59T1BKSV;CG95YEZJ;SNEVEHG1',
      statusContent: 'dot',
      hash: 'GBXYSL4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBXYSL4E'
    },
    {
      status: 210,
      sku: 'PM000138728',
      batchNumber: '6318893',
      btch_manufacturer: 975,
      name_search: 'EUJKATHY',
      name: "Euonymus japonicus 'Kathy'",
      rng_range_identifier: 'STEM050C3',
      sppl_size_code: '050STC3',
      sppl_stock_available: 629,
      sppl_prcp: 8.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 17,
      imageCore:
        '62LSXHZ2;55WJY8RR;HNJR5R98;EP4HKKHS;JD5XE33P;HELHT1P6;W58N4WWL;LWNSSXTT;EJNW49PY;TXC4AX3V;LGNBP2KG;L3Z7SYAW;XK9VKPWB;AHA9X1P7;EL9P9C56;CD675LAY;G5TT5EGX',
      statusContent: 'dot',
      hash: 'KBCE1GJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBCE1GJ7'
    },
    {
      status: 210,
      sku: 'PM000138729',
      batchNumber: '6318901',
      btch_manufacturer: 975,
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      rng_range_identifier: 'C5',
      sppl_size_code: '050060C5',
      sppl_stock_available: 225,
      sppl_prcp: 5.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 6,
      imageCore: 'E3HW2RZH;BTAJAVYK;YGBH5TJ9;28E4E2WB;JKB66W1D;WYSKW4KA',
      statusContent: 'dot',
      hash: '5WEC41RC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WEC41RC'
    },
    {
      status: 210,
      sku: 'PM000138730',
      batchNumber: '6318956',
      btch_manufacturer: 489,
      name_search: 'MAFMBLUS',
      name: 'Magnolia Fairy Magnolia Blush',
      rng_range_identifier: 'P9',
      sppl_size_code: '010015P9',
      sppl_stock_available: 200,
      sppl_order_minimum: 8,
      sppl_prcp: 5.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H6AXLAAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6AXLAAW'
    },
    {
      status: 210,
      sku: 'PM000138731',
      batchNumber: '6319238',
      btch_manufacturer: 14143637,
      name_search: 'TEPAPYRI',
      name: 'Tetrapanax papyrifer',
      rng_range_identifier: 'C2',
      sppl_size_code: 'LEVC2',
      sppl_stock_available: 100,
      sppl_prcp: 8.13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '56TXAV2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56TXAV2V'
    },
    {
      status: 990,
      sku: 'PM000010332',
      batchNumber: '5825982',
      btch_manufacturer: 170,
      name_search: 'EDCWLIEB',
      name: "Edgeworthia chrysantha 'Winter Liebe'",
      rng_range_identifier: 'C5',
      sppl_size_code: '040050C5',
      sppl_stock_available: 0,
      sppl_prcp: 15.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6L72AZXN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6L72AZXN'
    },
    {
      status: 810,
      sku: 'PM000138732',
      batchNumber: '6319239',
      btch_manufacturer: 14143637,
      name_search: 'TEPAPYRI',
      name: 'Tetrapanax papyrifer',
      rng_range_identifier: 'C5',
      sppl_size_code: 'LEVC5',
      sppl_stock_available: 25,
      sppl_prcp: 15.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AZSBDKRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZSBDKRK'
    },
    {
      status: 210,
      sku: 'PM000138733',
      batchNumber: '6319242',
      btch_manufacturer: 143241,
      name_search: 'CAVULGAR',
      name: 'Calluna vulgaris',
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 2200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: 'T9T225SH;NZR6CJWY',
      statusContent: 'dot',
      hash: '4ECD93AV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ECD93AV'
    },
    {
      status: 210,
      sku: 'PM000138734',
      batchNumber: '6319243',
      btch_manufacturer: 143241,
      name_search: 'ERCINERE',
      name: 'Erica cinerea',
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 2200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6335KDYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6335KDYB'
    },
    {
      status: 990,
      sku: 'PM000010318',
      batchNumber: '5825906',
      btch_manufacturer: 5,
      name_search: 'PRSKANZA',
      name: "Prunus serrulata 'Kanzan'",
      rng_range_identifier: 'SPECH150200C20',
      sppl_size_code: '150200C20SO',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 19,
      imageCore:
        'DV4JDL4Y;WHNP7PGB;6C76X61W;N8B9H4WJ;V8JA872V;A6VH4ADJ;53R5ZY8E;GR7A85XD;D7N5BRB3;9THHPCC1;N23GAZ59;G3W6VYJ4;NWH659YX;AP7SL1V1;44SC798X;6GZ55148;4CVXX54L;ZHANTX5N;CT9LKGWX',
      statusContent: 'dot',
      hash: 'ARTL7AW2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ARTL7AW2'
    },
    {
      status: 910,
      sku: 'PM000138735',
      batchNumber: '6319332',
      btch_manufacturer: 1585,
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      rng_range_identifier: 'H200250RB',
      sppl_size_code: '200250KLMST',
      sppl_stock_available: 8,
      sppl_prcp: 163.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 10,
      imageCore:
        'RHR9DDT5;YY2BGV4V;B2H32ALH;KW5WG6XC;L781LJ95;8P8BGCSR;Z9XJNYD8;VZ2SSTKV;B272SLNG;BASKYHWE',
      statusContent: 'dot',
      hash: 'NEG4RCH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NEG4RCH1'
    },
    {
      status: 990,
      sku: 'PM000010315',
      batchNumber: '5825903',
      btch_manufacturer: 5,
      name_search: 'PRACCOLA',
      name: "Prunus 'Accolade'",
      rng_range_identifier: 'SPECH150200C20',
      sppl_size_code: '150200C20SO',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 20,
      imageCore:
        'SH74NPTB;VZ75X17P;NV72ETJX;GE3XEA2C;AJ4BH54P;9EY94ESP;PRP8VTXS;59TEAH1P;HHNJGHB9;SCR84PX9;JYAKS9HN;VDKSR9J5;WGPJ1AEX;TH6WVLNT;W8C4PYKS;9BV1B1HH;1ZV497HR;6BWLL5T6;521BKKSK;LR1Y25D5',
      statusContent: 'dot',
      hash: '244N5CBC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '244N5CBC'
    },
    {
      status: 910,
      sku: 'PM000138736',
      batchNumber: '6319333',
      btch_manufacturer: 1585,
      name_search: 'CABIGNON',
      name: 'Catalpa bignonioides',
      rng_range_identifier: 'SHI008010RB',
      sppl_size_code: '008010HOKL',
      sppl_stock_available: 1,
      sppl_prcp: 65.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YCPWRJXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCPWRJXB'
    },
    {
      status: 910,
      sku: 'PM000138737',
      batchNumber: '6319334',
      btch_manufacturer: 1585,
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      rng_range_identifier: 'H200250RB',
      sppl_size_code: '200250KLMST',
      sppl_stock_available: 2,
      sppl_prcp: 89.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 25,
      imageCore:
        'B277H6AT;V5AC7HSX;ZBBTDWCW;XB172A7R;ZZ3BLELX;RBLZV6R4;44BHDZCC;YSEHW2LG;KBRH655X;9PY4C27B;L3RZDRCE;1CHN7GP8;8YHNPWPN;K13PTGAG;7DJLJ135;HXZT5HPK;HZB95WB2;TK83XV7V;6BREZKZE;LL42H69V;8H2NA4RH;DZXL3ENS;73VTCW4W;ZH3VZDPC;2B112CBZ',
      statusContent: 'dot',
      hash: '9EW5CGPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EW5CGPV'
    },
    {
      status: 990,
      sku: 'PM000010293',
      batchNumber: '5825790',
      btch_manufacturer: 238,
      name_search: 'ARITALIC',
      name: 'Arum italicum',
      rng_range_identifier: 'P11',
      sppl_size_code: 'LEVP11',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 23,
      imageCore:
        'TEP6BLBN;SDNH3K5G;W5V3N92P;TB5AX26D;W3396WYN;GK7D7L32;A34S76HA;DE8E1T7X;52RNLC3V;Z6B7LS1P;WNSN65VB;K42EKPNP;VCA7R5P2;HV4NZTJS;E34SDZJK;XV1R3S72;C76SBY51;KDXZ2EJD;PV9L44NH;ZCPCER74;J9BPL7XB;RSZCKKY8;YCG3YTBL',
      imageBatchTotal: 1,
      imageBatch: 'WV3CYYYZ',
      statusContent: 'dot',
      hash: 'YNHV1A2J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YNHV1A2J'
    },
    {
      status: 990,
      sku: 'PM000010290',
      sppl_ean: '0872066461108',
      batchNumber: '5825787',
      btch_manufacturer: 706,
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      rng_range_identifier: 'H100125RB',
      sppl_size_code: '100125KL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 12,
      imageCore:
        'L7SNZ8KT;D9KWXWEK;ZPHTS9GT;PW2TJVCS;HPD5Z41E;SV9KA7JZ;SNDA44WD;NHYHNESS;E3LAE99N;71TY1G1P;L8LAC1E8;ZTWL7YWJ',
      statusContent: 'dot',
      hash: 'GVX67GTW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GVX67GTW'
    },
    {
      status: 910,
      sku: 'PM000010323',
      batchNumber: '5825911',
      btch_manufacturer: 5,
      name_search: 'CHPFNANA',
      name: "Chamaecyparis pisifera 'Filifera Nana'",
      rng_range_identifier: 'C35',
      sppl_size_code: '080100C35',
      sppl_stock_available: 7,
      sppl_prcp: 37.039,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'HKG3LHZV;8PDBLBCA;VN7JA8GX;A7RLX4BC',
      statusContent: 'dot',
      hash: 'DXKBR3RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXKBR3RT'
    },
    {
      status: 990,
      sku: 'PM000010359',
      batchNumber: '5826030',
      btch_manufacturer: 681,
      name_search: 'PHALBATR',
      name: "Philadelphus 'Albâtre'",
      rng_range_identifier: 'BR0+1+1',
      sppl_size_code: 'PLG011',
      sppl_stock_available: 0,
      sppl_prcp: 0.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YTKSX7AV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YTKSX7AV'
    },
    {
      status: 990,
      sku: 'PM000010309',
      batchNumber: '5825897',
      btch_manufacturer: 5,
      name_search: 'MAEVERES',
      name: "Malus 'Evereste'",
      rng_range_identifier: 'SPECH150175C20',
      sppl_size_code: '150175C20SO',
      sppl_stock_available: 0,
      sppl_prcp: 50.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 26,
      imageCore:
        '1BXRBHRP;KPB798LG;DEY2R18R;4J61LCAT;BE669GGL;XLWV5JL1;8KR86ZGR;JREG8SN3;SD19AZG4;57PD44NG;ZR5CN694;EGRANJSH;4TSL1TK3;DKNT3Y2W;P6PN4W6R;VH8S726B;WGPLGVAR;GLZPKYD5;1746PKLW;ZRLZBNW9;VAZD32WZ;ENL8GASY;C75RL4DC;8T5HA3NW;W5G5LAT9;WAXXK7XK',
      statusContent: 'dot',
      hash: 'C65ZRDBY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C65ZRDBY'
    },
    {
      status: 810,
      sku: 'PM000010314',
      batchNumber: '5825902',
      btch_manufacturer: 5,
      name_search: 'PAPVANES',
      name: "Parrotia persica 'Vanessa'",
      rng_range_identifier: 'H100125C20',
      sppl_size_code: '100125C20',
      sppl_stock_available: 31,
      sppl_prcp: 38.743,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XB1HPX3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XB1HPX3H'
    },
    {
      status: 990,
      sku: 'PM000010319',
      batchNumber: '5825907',
      btch_manufacturer: 5,
      name_search: 'SYVAMETH',
      name: "Syringa vulgaris 'Amethyst'",
      rng_range_identifier: 'SPECH150175C20',
      sppl_size_code: '150175C20SO',
      sppl_stock_available: 0,
      sppl_prcp: 50.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJ8EVJR2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LJ8EVJR2'
    },
    {
      status: 210,
      sku: 'PM000010289',
      batchNumber: '5825778',
      btch_manufacturer: 14143510,
      name_search: 'CIENDIVI',
      name: 'Cichorium endivia',
      rng_range_identifier: 'SETCN6',
      sppl_size_code: '6SP9T',
      sppl_stock_available: 900,
      sppl_order_minimum: 1,
      sppl_prcp: 0.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 13,
      imageCore:
        'EC1YG49J;H5ELT2G1;K2GDW8J8;Y2SN7YAL;2EGPP97E;N4A4RX3G;AY2AEYSP;5KZ7B5RW;G9247RVX;BGZ992JE;BP3EXBWR;STCKTHDN;K95B86V7',
      imageBatchTotal: 1,
      imageBatch: 'NHVR8CCB',
      statusContent: 'dot',
      hash: '6T1JV94B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6T1JV94B'
    },
    {
      status: 910,
      sku: 'PM000010317',
      batchNumber: '5825905',
      btch_manufacturer: 5,
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      rng_range_identifier: 'H100125C20',
      sppl_size_code: '100125C20',
      sppl_stock_available: 12,
      sppl_prcp: 36.193,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 17,
      imageCore:
        '4J9T81CX;5SPLN6KE;LEHNW1P9;157GGNAD;4Z6KNRW4;7E5ABDAX;NGKT67CD;Y84CAYHH;VJZ5DE86;8J6H9TJ4;NZVZBSPC;C772GRBZ;7TSGW2RA;DG912T71;V52A27G4;5YRGHBYV;96224TKZ',
      statusContent: 'dot',
      hash: 'H5Z31HJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5Z31HJY'
    },
    {
      status: 990,
      sku: 'PM000010313',
      batchNumber: '5825901',
      btch_manufacturer: 5,
      name_search: 'MATSCARL',
      name: "Malus toringo 'Scarlett'",
      rng_range_identifier: 'SPECH150200C20',
      sppl_size_code: '150200C20SO',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z74PDLNN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z74PDLNN'
    },
    {
      status: 990,
      sku: 'PM000010307',
      batchNumber: '5825895',
      btch_manufacturer: 5,
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      rng_range_identifier: 'H100125C20',
      sppl_size_code: '100125C20',
      sppl_stock_available: 0,
      sppl_prcp: 42.104,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 12,
      imageCore:
        'JPY5VK14;47Z3LBPS;PN9YSADK;W7V9Y2HN;7CVBY7PJ;9CZ1HBA4;DH9LCHBK;6KRZ6BV5;XK83XP2N;JW8S3GWT;JCSK1KA2;NA9TPJ94',
      statusContent: 'dot',
      hash: 'RVYHY8SD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RVYHY8SD'
    },
    {
      status: 990,
      sku: 'PM000010288',
      batchNumber: '5825777',
      btch_manufacturer: 407,
      name_search: 'SYJOSIKA',
      name: 'Syringa josikaea',
      sppl_size_code: 'R06R10',
      sppl_stock_available: 0,
      sppl_prcp: 0.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TT4DYCVD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TT4DYCVD'
    },
    {
      status: 990,
      sku: 'PM000096247',
      batchNumber: '5515395',
      btch_manufacturer: 1439,
      name_search: 'CRPSPLEN',
      name: "Crataegus persimilis 'Splendens'",
      rng_range_identifier: 'SHI008010RBWIR',
      sppl_size_code: '008010HODKL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P5DCVW87',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P5DCVW87'
    },
    {
      status: 990,
      sku: 'PM000010333',
      batchNumber: '5825983',
      btch_manufacturer: 170,
      name_search: 'EDCWLIEB',
      name: "Edgeworthia chrysantha 'Winter Liebe'",
      rng_range_identifier: 'C10',
      sppl_size_code: '050060C10',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5NWALT5C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5NWALT5C'
    },
    {
      status: 990,
      sku: 'PM000010311',
      batchNumber: '5825899',
      btch_manufacturer: 5,
      name_search: 'MARSENTI',
      name: "Malus 'Red Sentinel'",
      rng_range_identifier: 'SPECH150200C20',
      sppl_size_code: '150200C20SO',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 8,
      imageCore:
        '7NXEGNR5;6LJ3E9JZ;TV8STC5P;H64LGET2;K6GKV6KG;ZHJ9KX69;LHT9XJJH;P7ZDDRSJ',
      statusContent: 'dot',
      hash: '8ASLSYBY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8ASLSYBY'
    },
    {
      status: 990,
      sku: 'PM000010342',
      sppl_ean: '8720664858985',
      batchNumber: '5826008',
      btch_manufacturer: 413,
      name_search: 'CRGDAVID',
      name: "Crocosmia 'George Davidson'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NC9YCTA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6NC9YCTA'
    },
    {
      status: 910,
      sku: 'PM000138738',
      batchNumber: '6319337',
      btch_manufacturer: 1585,
      name_search: 'MADOMEST',
      name: 'Malus domestica',
      rng_range_identifier: 'SHI012014RB',
      sppl_size_code: '012014HOKL',
      sppl_stock_available: 2,
      sppl_prcp: 136.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 28,
      imageCore:
        'ZTJNZCTB;GJXGVZC8;V268SLH9;HZSYH5B5;7N2XEHE5;CW9ZDXS5;2ZL268G6;W81CN9DW;H7DARZAY;8DXCL9V1;WT1L3271;A5H9LPVR;DA52WC9E;PLY5NNV1;5ZT1TCL3;SPGSN1NK;NWGYZK2E;CD77ZEKP;JP35YYPG;KBD5BPSS;PH73GS8S;57W95LV7;HZLPNY96;TZDXXCN2;4D6KJTRJ;6K147KCH;2GCVN238;NPWJB6Y8',
      statusContent: 'dot',
      hash: 'V357YD2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V357YD2H'
    },
    {
      status: 910,
      sku: 'PM000138739',
      batchNumber: '6319339',
      btch_manufacturer: 1585,
      name_search: 'PRDOMEST',
      name: 'Prunus domestica',
      rng_range_identifier: 'SHI012014RB',
      sppl_size_code: '012014HOKL',
      sppl_stock_available: 1,
      sppl_prcp: 136.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 25,
      imageCore:
        'BXLX41ZR;13J9VRG2;43X4HPWV;G7KCP9NE;7PCB8VLH;LRS6DDV2;RAC8YE49;Y2CN8778;WEYP7SHA;A18THDY1;DWLTAJ89;9K1EWCLV;K972C2NZ;1BVBV4EB;BSS7Y396;K1A97T7A;E5SCLEGL;X2SWPPL2;G6LHSREV;JV21SVEX;15T45WBW;61ZZ4ETV;242JB3B9;4H48L7RB;X2CWWVDA',
      statusContent: 'dot',
      hash: 'SS7864SY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS7864SY'
    },
    {
      status: 910,
      sku: 'PM000138740',
      batchNumber: '6319340',
      btch_manufacturer: 1585,
      name_search: 'PYCOMMUN',
      name: 'Pyrus communis',
      rng_range_identifier: 'SHI012014RB',
      sppl_size_code: '012014HOKL',
      sppl_stock_available: 1,
      sppl_prcp: 136.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 23,
      imageCore:
        'WRWA5LR9;PXZ1T31D;4THP82HW;JSWJ52HK;XJ9E8JHE;7V17CY6L;GLS2BKED;DPL7LK8V;DP77LTK2;LV7NS56X;ZWXG9YRD;EZJY4343;KZ555BCG;S49BW27K;H4GXKXJD;C71V92WR;DHE1AE1G;GX6ZTTNK;Y1TNE7BD;XGW7EBW6;STDPTTAR;15DHR2RT;BJR44ASV',
      statusContent: 'dot',
      hash: 'N9VE86D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9VE86D7'
    },
    {
      status: 990,
      sku: 'PM000096248',
      batchNumber: '5515396',
      btch_manufacturer: 1439,
      name_search: 'CRPSPLEN',
      name: "Crataegus persimilis 'Splendens'",
      rng_range_identifier: 'SHI010012RBWIR',
      sppl_size_code: '010012HODKL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z5GXCCLG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z5GXCCLG'
    },
    {
      status: 210,
      sku: 'PM000138742',
      batchNumber: '6319460',
      btch_manufacturer: 1005,
      name_search: 'PHOANDRE',
      name: "Physocarpus opulifolius 'Andre'",
      rng_range_identifier: 'C3',
      sppl_size_code: '040050C3',
      sppl_stock_available: 500,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GT9CZ2X8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT9CZ2X8'
    },
    {
      status: 990,
      sku: 'PM000010382',
      sppl_ean: '8720664865365',
      batchNumber: '5826111',
      btch_manufacturer: 2472,
      name_search: 'EDI64974',
      name: 'Gladiolus communis byzantinus',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0.851,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GX3JX1G2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GX3JX1G2'
    },
    {
      status: 210,
      sku: 'PM000010388',
      sppl_ean: '0872066458833',
      batchNumber: '5826117',
      btch_manufacturer: 2472,
      name_search: 'ORUMBELL',
      name: 'Ornithogalum umbellatum',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 194,
      sppl_order_minimum: 3,
      sppl_prcp: 0.647,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XJE6C51R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJE6C51R'
    },
    {
      status: 990,
      sku: 'PM000010370',
      batchNumber: '5826047',
      btch_manufacturer: 413,
      name_search: 'CERCOCCI',
      name: "Centranthus ruber 'Coccineus'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 7,
      imageCore:
        'RDV64DCP;BWZ95LB9;4XZVVJWH;4XRE8751;HNY8ZYNY;WTL471RP;1CB9DBR9',
      statusContent: 'dot',
      hash: 'Z7BBX2AX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z7BBX2AX'
    },
    {
      status: 990,
      sku: 'PM000010371',
      batchNumber: '5826048',
      btch_manufacturer: 413,
      name_search: 'EUPOLYCH',
      name: 'Euphorbia polychroma',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '821TSBLG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '821TSBLG'
    },
    {
      status: 990,
      sku: 'PM000010373',
      batchNumber: '5826050',
      btch_manufacturer: 413,
      name_search: 'SOGOLDKI',
      name: "Solidago 'Goldkind'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4T3P1ZWH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4T3P1ZWH'
    },
    {
      status: 990,
      sku: 'PM000010378',
      batchNumber: '5826107',
      btch_manufacturer: 31,
      name_search: 'SOTORMIN',
      name: 'Sorbus torminalis',
      rng_range_identifier: 'BR1+1',
      sppl_size_code: '06008011',
      sppl_stock_available: 0,
      sppl_order_minimum: 25,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KJTXR745',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KJTXR745'
    },
    {
      status: 910,
      sku: 'PM000010525',
      sppl_ean: '8720349436606',
      batchNumber: '5826265',
      btch_manufacturer: 1260,
      name_search: 'ALVULGAR',
      name: 'Alchemilla vulgaris',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 3,
      sppl_order_minimum: 3,
      sppl_prcp: 0.882,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 3,
      imageCore: 'KZJLE3C3;XR2WXTVG;EDZVZPH2',
      statusContent: 'dot',
      hash: 'VSJE65V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSJE65V1'
    },
    {
      status: 210,
      sku: 'PM000138743',
      batchNumber: '6319461',
      btch_manufacturer: 1005,
      name_search: 'SPJFROEB',
      name: "Spiraea japonica 'Froebelii'",
      rng_range_identifier: 'C3',
      sppl_size_code: '030040C3',
      sppl_stock_available: 100,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: '6BEPVN5S',
      statusContent: 'dot',
      hash: 'ETBNCZ8S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETBNCZ8S'
    },
    {
      status: 910,
      sku: 'PM000138744',
      batchNumber: '6319581',
      btch_manufacturer: 1585,
      name_search: 'TICORDAT',
      name: 'Tilia cordata',
      rng_range_identifier: 'STHI014016',
      sppl_size_code: '014016HOCO',
      sppl_stock_available: 2,
      sppl_prcp: 136.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 7,
      imageCore:
        'Y18W5GN9;6C5YLE2L;93HLGHSJ;TD7ZXCRJ;75K69YWA;P5D4TGRS;XJEZH777',
      statusContent: 'dot',
      hash: '1W33TJRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1W33TJRL'
    },
    {
      status: 990,
      sku: 'PM000096250',
      batchNumber: '5515403',
      btch_manufacturer: 1439,
      name_search: 'MEGERMAN',
      name: 'Mespilus germanica',
      rng_range_identifier: 'H150175RBWIR',
      sppl_size_code: '150175DKLMS',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 18,
      imageCore:
        'XCR1EHR3;RKDV4BXC;E7YG4HV7;2DRWXPRD;7S94DVXW;N3PS1K8T;TE95RAY1;93WHDN2G;NHDKRVBG;B7LVSNK7;7PP5EA5C;9WRN7HXK;W1Y7GKAD;7X968C5B;VC9J51EP;BZLAVREL;SSYCXWG7;2R86DY81',
      statusContent: 'dot',
      hash: 'ARNDZ381',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ARNDZ381'
    },
    {
      status: 910,
      sku: 'PM000138745',
      batchNumber: '6319582',
      btch_manufacturer: 1585,
      name_search: 'SOASSEED',
      name: "Sorbus aucuparia 'Sheerwater Seedling'",
      rng_range_identifier: 'STHI010012',
      sppl_size_code: '010012HOCO',
      sppl_stock_available: 3,
      sppl_prcp: 101.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J6195AVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6195AVA'
    },
    {
      status: 910,
      sku: 'PM000138746',
      batchNumber: '6319688',
      btch_manufacturer: 172,
      name_search: 'ASMLARS',
      name: "Astrantia major 'Lars'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 1,
      sppl_order_minimum: 3,
      sppl_prcp: 0.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G3LE2SL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3LE2SL2'
    },
    {
      status: 210,
      sku: 'PM000138747',
      batchNumber: '6319718',
      btch_manufacturer: 172,
      name_search: 'MUCOMPLE',
      name: 'Muehlenbeckia complexa',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 7,
      imageCore:
        'NYC3W3DL;VTS9RN7Z;7BAW3VEW;PXD1JYWE;CD68CV75;NCPS1XZL;LRPEPWC6',
      statusContent: 'dot',
      hash: 'LCB3S3YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCB3S3YT'
    },
    {
      status: 810,
      sku: 'PM000138748',
      batchNumber: '6319730',
      btch_manufacturer: 172,
      name_search: 'PHKING',
      name: "Phlox (P) 'The King'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'ATC9KWZX',
      statusContent: 'dot',
      hash: 'S1ECT9HC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1ECT9HC'
    },
    {
      status: 990,
      sku: 'PM000096253',
      batchNumber: '5515408',
      btch_manufacturer: 1439,
      name_search: 'PRPADUS',
      name: 'Prunus padus',
      rng_range_identifier: 'SHI010012RBWIR',
      sppl_size_code: '010012HODKL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'NR134WVZ;BJJZVXPX;ALJH783V;K6PZ2KZ8',
      statusContent: 'dot',
      hash: 'H67GDK7E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H67GDK7E'
    },
    {
      status: 810,
      sku: 'PM000138749',
      batchNumber: '6319731',
      btch_manufacturer: 172,
      name_search: 'PHUTOPIA',
      name: "Phlox (P) 'Utopia'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 58,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4VCDYLKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VCDYLKR'
    },
    {
      status: 990,
      sku: 'PM000096254',
      batchNumber: '5515409',
      btch_manufacturer: 1439,
      name_search: 'PRPCOLOR',
      name: "Prunus padus 'Colorata'",
      rng_range_identifier: 'SHI006008RBWIR',
      sppl_size_code: '006008HODKL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VW969RKT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VW969RKT'
    },
    {
      status: 810,
      sku: 'PM000138750',
      batchNumber: '6319739',
      btch_manufacturer: 172,
      name_search: 'SAOCRISP',
      name: "Salvia officinalis 'Crispa'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 6,
      imageCore: 'ABY1CGPY;LNVL45YY;EVDLVZGD;KZX1TVTH;KAJ7ZEPT;SRDZGCBB',
      statusContent: 'dot',
      hash: 'LSPX2DED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSPX2DED'
    },
    {
      status: 210,
      sku: 'PM000138751',
      batchNumber: '6319743',
      btch_manufacturer: 172,
      name_search: 'LAANALBA',
      name: "Lavandula angustifolia 'Nana Alba'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'B3YDHDJY;ACDR72S8;7CVYXLSY;D3W4WHZR',
      statusContent: 'dot',
      hash: 'G6S4E1R9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6S4E1R9'
    },
    {
      status: 990,
      sku: 'PM000011086',
      sppl_ean: '8720664884212',
      batchNumber: '5826845',
      btch_manufacturer: 1260,
      name_search: 'RUTRILOB',
      name: 'Rudbeckia triloba',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0.779,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 3,
      imageCore: '9SE5XYSV;2ZZ4N8E9;2ZYK2LBB',
      statusContent: 'dot',
      hash: 'WZDXDPYS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WZDXDPYS'
    },
    {
      status: 990,
      sku: 'PM000011165',
      batchNumber: '5826927',
      btch_manufacturer: 5,
      name_search: 'MASROSEA',
      name: "Magnolia stellata 'Rosea'",
      rng_range_identifier: 'C70',
      sppl_size_code: '125150C70',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z64Y57WJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z64Y57WJ'
    },
    {
      status: 810,
      sku: 'PM000138752',
      batchNumber: '6319761',
      btch_manufacturer: 172,
      name_search: 'STATTENU',
      name: 'Strobilanthes attenuata',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 77,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GN36HZW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GN36HZW3'
    },
    {
      status: 210,
      sku: 'PM000138753',
      batchNumber: '6319765',
      btch_manufacturer: 172,
      name_search: 'VELPEVEL',
      name: "Veronica longifolia 'Pink Eveline'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZTT88JW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTT88JW6'
    },
    {
      status: 210,
      sku: 'PM000138754',
      batchNumber: '6319770',
      btch_manufacturer: 172,
      name_search: 'DACAROTA',
      name: 'Daucus carota',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JHLG3LXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHLG3LXG'
    },
    {
      status: 910,
      sku: 'PM000138755',
      batchNumber: '6319802',
      btch_manufacturer: 968,
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      rng_range_identifier: 'CONEH120130C30',
      sppl_size_code: '120130C30KE',
      sppl_stock_available: 2,
      sppl_prcp: 35.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 20,
      imageCore:
        'JTBJZD3Z;7D41P2ZV;AEL7Y31P;A4GNG9NN;A8HWG826;NS1ERYXE;41D92K8P;RBXDVA7G;HY7DNK1L;9JKBK52G;1S41KNA9;5CSJREJZ;LWP7G82S;X46BVA7J;77C81V5X;BLBL99TC;VZB4P3Y8;VA7JV9V3;CRV85RBP;YRA9ZXHZ',
      statusContent: 'dot',
      hash: 'V4CNL52W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4CNL52W'
    },
    {
      status: 210,
      sku: 'PM000138756',
      batchNumber: '6319822',
      btch_manufacturer: 1426,
      name_search: 'CHSCGOLD',
      name: "Chaenomeles superba 'Crimson and Gold'",
      rng_range_identifier: 'C10',
      sppl_size_code: '030040C10',
      sppl_stock_available: 266,
      sppl_prcp: 10.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 6,
      imageCore: '4SSSJXKG;62Y5ASLY;T64JS4CS;P1B1E45E;45HZNLBK;2Z75HD8P',
      statusContent: 'dot',
      hash: 'TVD8LGGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVD8LGGP'
    },
    {
      status: 810,
      sku: 'PM000138757',
      batchNumber: '6319835',
      btch_manufacturer: 1426,
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      rng_range_identifier: 'H100120C10',
      sppl_size_code: '100120C10',
      sppl_stock_available: 89,
      sppl_prcp: 11.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GYSEDL4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYSEDL4G'
    },
    {
      status: 210,
      sku: 'PM000138758',
      batchNumber: '6319837',
      btch_manufacturer: 1426,
      name_search: 'ENCRBELL',
      name: "Enkianthus campanulatus 'Red Bells'",
      rng_range_identifier: 'C10',
      sppl_size_code: '060080C10',
      sppl_stock_available: 225,
      sppl_prcp: 12.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EAGWKA68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAGWKA68'
    },
    {
      status: 210,
      sku: 'PM000138759',
      batchNumber: '6319844',
      btch_manufacturer: 1426,
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      rng_range_identifier: 'C10',
      sppl_size_code: '040060C10',
      sppl_stock_available: 290,
      sppl_prcp: 10.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 3,
      imageCore: '4848N66P;VC29NHLP;GB28AEPZ',
      statusContent: 'dot',
      hash: '8WA5974V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WA5974V'
    },
    {
      status: 210,
      sku: 'PM000138760',
      batchNumber: '6319854',
      btch_manufacturer: 1426,
      name_search: 'LEBYAKUS',
      name: "Lespedeza bicolor 'Yakushima'",
      rng_range_identifier: 'C10',
      sppl_size_code: '035040C10',
      sppl_stock_available: 190,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D7GVN529',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7GVN529'
    },
    {
      status: 910,
      sku: 'PM000138761',
      batchNumber: '6319874',
      btch_manufacturer: 1426,
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      rng_range_identifier: 'C10',
      sppl_size_code: '040060C10',
      sppl_stock_available: 12,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 11,
      imageCore:
        '26J2SJ42;6L2BV61B;N1N9KGXE;V7Z34DV7;RPBKPZL1;B5BVR8Y7;B232HRW1;YG8DL666;BZPV78AZ;TLVB5759;SRTPLRRC',
      statusContent: 'dot',
      hash: 'B3B9APWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3B9APWB'
    },
    {
      status: 210,
      sku: 'PM000138762',
      batchNumber: '6319880',
      btch_manufacturer: 1426,
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      rng_range_identifier: 'H100125C10',
      sppl_size_code: '100125C10',
      sppl_stock_available: 178,
      sppl_prcp: 10.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 13,
      imageCore:
        '5EW5STG6;B9XGWJA6;NKX1HKNL;HJ4J8AT6;8L83271L;PBNNC9XD;ZN48TK3N;15YPCPP9;L2VXPXTG;4T7AJL9T;VVX63GYC;K6HZWY3W;JXRTTNY5',
      statusContent: 'dot',
      hash: 'D2V5B4D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2V5B4D5'
    },
    {
      status: 910,
      sku: 'PM000011176',
      batchNumber: '5826946',
      btch_manufacturer: 5,
      name_search: 'PAPVANES',
      name: "Parrotia persica 'Vanessa'",
      rng_range_identifier: 'C20',
      sppl_size_code: '175200C20',
      sppl_stock_available: 1,
      sppl_prcp: 63.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '855S7YVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '855S7YVX'
    },
    {
      status: 210,
      sku: 'PM000138763',
      batchNumber: '6319881',
      btch_manufacturer: 1426,
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      rng_range_identifier: 'H100120C10',
      sppl_size_code: '100120C10',
      sppl_stock_available: 182,
      sppl_prcp: 10.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 16,
      imageCore:
        '8S3HKZJ8;ECBJ98CE;LC4AZ976;5X3BRPG9;8RR2L7XZ;DB76BNEE;BLB8EX3G;NYP57E5E;CZW1GKPK;SE3BX163;V957DGRB;24R991HH;TWCNE24Z;11CT7NL8;R8C1THBZ;YPKHTPB9',
      statusContent: 'dot',
      hash: '2END8HDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2END8HDB'
    },
    {
      status: 210,
      sku: 'PM000138764',
      batchNumber: '6319887',
      btch_manufacturer: 1426,
      name_search: 'ACDAVIDI',
      name: 'Acer davidii',
      rng_range_identifier: 'H100125C10',
      sppl_size_code: '100125C10',
      sppl_stock_available: 143,
      sppl_prcp: 16.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: '41BTX8W8;9LCG41T5',
      statusContent: 'dot',
      hash: 'LLS41N3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLS41N3G'
    },
    {
      status: 810,
      sku: 'PM000138765',
      batchNumber: '6319896',
      btch_manufacturer: 1426,
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      rng_range_identifier: 'H100120C10',
      sppl_size_code: '100120C10',
      sppl_stock_available: 86,
      sppl_prcp: 10.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'AP7YWG23;WR3CJP9Z;SPT4VYH3;BCLN1XPH',
      statusContent: 'dot',
      hash: 'NT2LH4TA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NT2LH4TA'
    },
    {
      status: 210,
      sku: 'PM000138766',
      batchNumber: '6319920',
      btch_manufacturer: 14143282,
      name_search: 'LYPUNCTA',
      name: 'Lysimachia punctata',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 712,
      sppl_order_minimum: 3,
      sppl_prcp: 0.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: '2XY98J2Y;Z71STDC8;WPNLGV2C;T7EJ8B7A',
      statusContent: 'dot',
      hash: 'Z71JTLL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z71JTLL7'
    },
    {
      status: 990,
      sku: 'PM000011158',
      batchNumber: '5826920',
      btch_manufacturer: 5,
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      rng_range_identifier: 'H150175C65',
      sppl_size_code: '150175C65',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: '56YLL66L',
      statusContent: 'dot',
      hash: 'ZLWCVZ59',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZLWCVZ59'
    },
    {
      status: 990,
      sku: 'PM000011126',
      batchNumber: '5826886',
      btch_manufacturer: 1260,
      name_search: 'VECMAMMU',
      name: "Vernonia crinita 'Mammuth'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SSYEALKC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SSYEALKC'
    },
    {
      status: 990,
      sku: 'PM000011184',
      batchNumber: '5826954',
      btch_manufacturer: 2182,
      name_search: 'ROSHONEY',
      name: 'Rosa (K) Honeymoon',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5KAZBZPE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5KAZBZPE'
    },
    {
      status: 990,
      sku: 'PM000096255',
      batchNumber: '5515410',
      btch_manufacturer: 1439,
      name_search: 'PRSERRUL',
      name: 'Prunus serrula',
      rng_range_identifier: 'SHI012014RBWIR',
      sppl_size_code: '012014HODKL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'CTHSHVB6',
      statusContent: 'dot',
      hash: 'D7L5448Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D7L5448Y'
    },
    {
      status: 990,
      sku: 'PM000011183',
      batchNumber: '5826953',
      btch_manufacturer: 2182,
      name_search: 'ROHROSTO',
      name: 'Rosa (F) Hansestadt Rostock',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HH4B46R9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HH4B46R9'
    },
    {
      status: 990,
      sku: 'PM000011175',
      batchNumber: '5826945',
      btch_manufacturer: 5,
      name_search: 'MATBBEAU',
      name: "Malus toringo 'Brouwers Beauty'",
      rng_range_identifier: 'RB',
      sppl_size_code: '175200KL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N9HPS4K9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N9HPS4K9'
    },
    {
      status: 990,
      sku: 'PM000011200',
      batchNumber: '5826970',
      btch_manufacturer: 2182,
      name_search: 'RODDAYS',
      name: 'Rosa (T) Diamond Days',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T3YRSXE4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T3YRSXE4'
    },
    {
      status: 990,
      sku: 'PM000011220',
      batchNumber: '5826990',
      btch_manufacturer: 2182,
      name_search: 'ROYMEILO',
      name: 'Rosa Yellow Meilove',
      rng_range_identifier: 'STE090BR',
      sppl_size_code: '090STBW',
      sppl_stock_available: 0,
      sppl_prcp: 15.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5YSBJ9E3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5YSBJ9E3'
    },
    {
      status: 210,
      sku: 'PM000138767',
      batchNumber: '6319921',
      btch_manufacturer: 14143282,
      name_search: 'MISSTRIC',
      name: "Miscanthus sinensis 'Strictus'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 21,
      imageCore:
        'S454Y4S4;6S1ZAVHA;CYH3VPEH;PD5S4Y5C;HLE7BPGA;AD81XXDK;J1G6VZ6T;45Z7VAS6;AB32LAW2;R48E881Z;RAZ76L74;HCETZ35Y;RZB9L5L2;9S2EGK17;6K1BRK1J;1DXNBT8Y;WL5V4S6J;6DALTXDA;WD1ZSZXY;1TAGKS6B;5T1JD9GW',
      statusContent: 'dot',
      hash: 'GVTPTRD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVTPTRD5'
    },
    {
      status: 210,
      sku: 'PM000138768',
      batchNumber: '6319924',
      btch_manufacturer: 14143282,
      name_search: 'ORVULGAR',
      name: 'Origanum vulgare',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 470,
      sppl_order_minimum: 3,
      sppl_prcp: 0.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 25,
      imageCore:
        'VLSEAY46;4YREPA9L;WYYEY1ZS;7KVYXX31;ATSN1BTN;C4AR3JAT;XKJ9K7EG;B23YNHDS;4G7PYR28;LEY4PW5T;A9KTE7HJ;1P7E1APP;WPRE7X91;W1ZLSWW6;SKLVB1K3;HHBLC7N2;WL7SDCTJ;2SH76KKY;E9JCRWY5;ZN1K15RZ;XTH2A2TJ;X372EW1S;WN8C7JGN;SN4R48LD;2AB6VKZN',
      statusContent: 'dot',
      hash: '9C9HL7RP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9C9HL7RP'
    },
    {
      status: 990,
      sku: 'PM000011259',
      batchNumber: '5827033',
      btch_manufacturer: 2182,
      name_search: 'ROSBEYES',
      name: 'Rosa (PA) Sunshine Babylon Eyes',
      rng_range_identifier: 'STE090BR',
      sppl_size_code: '090STBW',
      sppl_stock_available: 0,
      sppl_prcp: 17.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '818VLYZ5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '818VLYZ5'
    },
    {
      status: 210,
      sku: 'PM000138769',
      batchNumber: '6319930',
      btch_manufacturer: 14143282,
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.76,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 12,
      imageCore:
        'H8K4VP7Y;NDRGX11S;8HRVXGK4;TAJVCKS2;2KCS29RC;CDPXJVHW;2EJ554NC;XAC3953H;SASXB773;SJVBW48C;JB5NAC45;D76PTAAK',
      statusContent: 'dot',
      hash: 'GEHBPLAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GEHBPLAC'
    },
    {
      status: 210,
      sku: 'PM000138770',
      batchNumber: '6319944',
      btch_manufacturer: 14143282,
      name_search: 'BUFPBHEA',
      name: 'Buddleja Free Petite Blue Heaven',
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 359,
      sppl_prcp: 3.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'E4V6W4JJ;H678WZDL;N9C7TKZD;GX94ZYJR',
      statusContent: 'dot',
      hash: 'D46BJP1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D46BJP1X'
    },
    {
      status: 210,
      sku: 'PM000138771',
      batchNumber: '6319949',
      btch_manufacturer: 14143282,
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 2007,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: '8GRJZXGV',
      statusContent: 'dot',
      hash: '1XG1HXH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XG1HXH9'
    },
    {
      status: 210,
      sku: 'PM000138772',
      batchNumber: '6319951',
      btch_manufacturer: 14143282,
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 1026,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'ADG5X22S',
      statusContent: 'dot',
      hash: 'DTTA4WED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTTA4WED'
    },
    {
      status: 210,
      sku: 'PM000138773',
      batchNumber: '6319961',
      btch_manufacturer: 14143282,
      name_search: 'HYPPLADY',
      name: "Hydrangea paniculata 'Pink Lady'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 608,
      sppl_prcp: 2.81,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HHC19NT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHC19NT1'
    },
    {
      status: 990,
      sku: 'PM000011256',
      batchNumber: '5827030',
      btch_manufacturer: 2182,
      name_search: 'ROGHERMI',
      name: 'Rosa (H) Gentle Hermione',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1VTZACB6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1VTZACB6'
    },
    {
      status: 210,
      sku: 'PM000138774',
      batchNumber: '6319968',
      btch_manufacturer: 14143282,
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 718,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 8,
      imageCore:
        'ADWJKWXC;6EAS9L9H;K9ZNKGGP;T9NRV5N5;LL1BV5CB;JPBRCK6P;J28GNDJ3;YCRGZKTH',
      statusContent: 'dot',
      hash: 'TXTWHBX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXTWHBX9'
    },
    {
      status: 210,
      sku: 'PM000138775',
      batchNumber: '6319969',
      btch_manufacturer: 14143282,
      name_search: 'POFANNET',
      name: "Potentilla fruticosa 'Annette'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 595,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1J68ND15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1J68ND15'
    },
    {
      status: 990,
      sku: 'PM000011216',
      batchNumber: '5826986',
      btch_manufacturer: 2182,
      name_search: 'ROEROSE',
      name: "Rosa (T) 'Eden Rose'",
      rng_range_identifier: 'STE090BR',
      sppl_size_code: '090STBW',
      sppl_stock_available: 0,
      sppl_prcp: 17.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '64EEEVCP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '64EEEVCP'
    },
    {
      status: 210,
      sku: 'PM000138776',
      batchNumber: '6319970',
      btch_manufacturer: 14143282,
      name_search: 'POFBLINK',
      name: "Potentilla fruticosa 'Blink'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 566,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 6,
      imageCore: 'XT1WX68Y;XJ7EDEY6;KW14SSAW;J1WZR15A;7YDBDVJR;95K1X733',
      statusContent: 'dot',
      hash: 'B7B63ASC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7B63ASC'
    },
    {
      status: 990,
      sku: 'PM000096256',
      batchNumber: '5515411',
      btch_manufacturer: 1439,
      name_search: 'PRSKANZA',
      name: "Prunus serrulata 'Kanzan'",
      rng_range_identifier: 'SHI012014RBWIR',
      sppl_size_code: '012014HODKL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 19,
      imageCore:
        'DV4JDL4Y;WHNP7PGB;6C76X61W;N8B9H4WJ;V8JA872V;A6VH4ADJ;53R5ZY8E;GR7A85XD;D7N5BRB3;9THHPCC1;N23GAZ59;G3W6VYJ4;NWH659YX;AP7SL1V1;44SC798X;6GZ55148;4CVXX54L;ZHANTX5N;CT9LKGWX',
      statusContent: 'dot',
      hash: '953JBYE6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '953JBYE6'
    },
    {
      status: 990,
      sku: 'PM000011228',
      batchNumber: '5826998',
      btch_manufacturer: 2182,
      name_search: 'ROMAROND',
      name: 'Rosa (H) Marondo',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HHLNWX5P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HHLNWX5P'
    },
    {
      status: 990,
      sku: 'PM000011251',
      batchNumber: '5827024',
      btch_manufacturer: 2182,
      name_search: 'ROFOPURP',
      name: 'Rosa (H) Fragrant Old Purple',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V2G4NHBY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V2G4NHBY'
    },
    {
      status: 990,
      sku: 'PM000011224',
      batchNumber: '5826994',
      btch_manufacturer: 2182,
      name_search: 'ROGWINGS',
      name: "Rosa (H) 'Golden Wings'",
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HVZ7NLPA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HVZ7NLPA'
    },
    {
      status: 990,
      sku: 'PM000011241',
      batchNumber: '5827013',
      btch_manufacturer: 2182,
      name_search: 'ROFELICI',
      name: "Rosa (H) 'Felicia'",
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4DAD6E65',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4DAD6E65'
    },
    {
      status: 990,
      sku: 'PM000011242',
      batchNumber: '5827014',
      btch_manufacturer: 2182,
      name_search: 'ROJCARTI',
      name: 'Rosa (H) Jacques Cartier',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KKEH2G6H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KKEH2G6H'
    },
    {
      status: 990,
      sku: 'PM000011244',
      batchNumber: '5827017',
      btch_manufacturer: 2182,
      name_search: 'ROCMUSCO',
      name: "Rosa centifolia 'Muscosa'",
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5VRNS35',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L5VRNS35'
    },
    {
      status: 990,
      sku: 'PM000011215',
      batchNumber: '5826985',
      btch_manufacturer: 2182,
      name_search: 'ROBONICA',
      name: "Rosa (F) 'Bonica'",
      rng_range_identifier: 'STE090BR',
      sppl_size_code: '090STBW',
      sppl_stock_available: 0,
      sppl_prcp: 15.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KAEPKTJ5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KAEPKTJ5'
    },
    {
      status: 990,
      sku: 'PM000011257',
      batchNumber: '5827031',
      btch_manufacturer: 2182,
      name_search: 'ROMINERV',
      name: 'Rosa (F) Minerva',
      rng_range_identifier: 'STE090BR',
      sppl_size_code: '090STBW',
      sppl_stock_available: 0,
      sppl_prcp: 17.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z66148L5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z66148L5'
    },
    {
      status: 990,
      sku: 'PM000011239',
      batchNumber: '5827011',
      btch_manufacturer: 2182,
      name_search: 'RORVICTO',
      name: "Rosa 'Reine Victoria'",
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V6BG5AZC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V6BG5AZC'
    },
    {
      status: 990,
      sku: 'PM000011219',
      batchNumber: '5826989',
      btch_manufacturer: 2182,
      name_search: 'RODDAYS',
      name: 'Rosa (T) Diamond Days',
      rng_range_identifier: 'STE090BR',
      sppl_size_code: '090STBW',
      sppl_stock_available: 0,
      sppl_prcp: 15.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VXEAXVGE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VXEAXVGE'
    },
    {
      status: 990,
      sku: 'PM000011217',
      batchNumber: '5826987',
      btch_manufacturer: 2182,
      name_search: 'ROMROSE',
      name: 'Rosa (T) Mary Rose',
      rng_range_identifier: 'STE090BR',
      sppl_size_code: '090STBW',
      sppl_stock_available: 0,
      sppl_prcp: 18.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CJPGP41D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CJPGP41D'
    },
    {
      status: 990,
      sku: 'PM000011203',
      batchNumber: '5826973',
      btch_manufacturer: 2182,
      name_search: 'ROCFASHI',
      name: 'Rosa (T) Crazy Fashion',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 3,
      imageCore: '7RWCSJR6;CXGE9V5A;4S39Z3AX',
      statusContent: 'dot',
      hash: 'AEGLT7LR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AEGLT7LR'
    },
    {
      status: 990,
      sku: 'PM000011202',
      batchNumber: '5826972',
      btch_manufacturer: 2182,
      name_search: 'ROWSPALE',
      name: 'Rosa Whiter Shade of Pale',
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'GCV6ETDS',
      statusContent: 'dot',
      hash: '17XX8AK3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '17XX8AK3'
    },
    {
      status: 990,
      sku: 'PM000011249',
      batchNumber: '5827022',
      btch_manufacturer: 2182,
      name_search: 'ROAPILLA',
      name: "Rosa (K) 'American Pillar'",
      rng_range_identifier: 'BR',
      sppl_size_code: 'LEVBWA',
      sppl_stock_available: 0,
      sppl_order_minimum: 10,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 5,
      imageCore: 'SBXLVE7P;CT4WJ2LE;EB1PLSCE;YG3C35W6;7DSENRWE',
      statusContent: 'dot',
      hash: 'A517YS3K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A517YS3K'
    },
    {
      status: 990,
      sku: 'PM000011268',
      batchNumber: '5827227',
      btch_manufacturer: 1302,
      name_search: 'ACPRRED',
      name: "Acer platanoides 'Royal Red'",
      rng_range_identifier: 'BR',
      sppl_size_code: '006008BW',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: '4W523JG4;GR4662X3',
      statusContent: 'dot',
      hash: 'CZZ7CV64',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CZZ7CV64'
    },
    {
      status: 990,
      sku: 'PM000011303',
      batchNumber: '5827270',
      btch_manufacturer: 466,
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      rng_range_identifier: 'RB',
      sppl_size_code: '050060KL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 13,
      imageCore:
        'LN2N4X5X;HJSB14X6;YY4ANN78;49NZ3C7V;N56Z3C7A;9LT67P4W;YY45PA7E;G3C5BCW3;K6HSHJLE;5491462Y;BSXHRW5C;RRCNBDCH;JBL8VP79',
      statusContent: 'dot',
      hash: '9995HWRK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9995HWRK'
    },
    {
      status: 990,
      sku: 'PM000011304',
      batchNumber: '5827271',
      btch_manufacturer: 466,
      name_search: 'HAIBGOLD',
      name: "Hamamelis intermedia 'Barmstedt Gold'",
      rng_range_identifier: 'RB',
      sppl_size_code: '050060KL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 8,
      imageCore:
        'AVHEN52D;G968ZD3J;PRR3AJBY;WTRETNDE;7LWTPZNE;73RXW8RV;KWSN5YSL;NXALZ3JW',
      statusContent: 'dot',
      hash: 'H3X2YV27',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H3X2YV27'
    },
    {
      status: 990,
      sku: 'PM000011305',
      batchNumber: '5827272',
      btch_manufacturer: 466,
      name_search: 'HAIBGOLD',
      name: "Hamamelis intermedia 'Barmstedt Gold'",
      rng_range_identifier: 'H100125RB',
      sppl_size_code: '100125KL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 8,
      imageCore:
        'AVHEN52D;G968ZD3J;PRR3AJBY;WTRETNDE;7LWTPZNE;73RXW8RV;KWSN5YSL;NXALZ3JW',
      statusContent: 'dot',
      hash: 'KY88LX21',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KY88LX21'
    },
    {
      status: 990,
      sku: 'PM000011308',
      batchNumber: '5827278',
      btch_manufacturer: 466,
      name_search: 'HAIFEUER',
      name: "Hamamelis intermedia 'Feuerzauber'",
      sppl_size_code: '060080CO',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'VS3AY5P1',
      statusContent: 'dot',
      hash: 'VLR19ZA4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VLR19ZA4'
    },
    {
      status: 210,
      sku: 'PM000138777',
      batchNumber: '6319972',
      btch_manufacturer: 14143282,
      name_search: 'POFLIMEL',
      name: "Potentilla fruticosa 'Limelight'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 585,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 3,
      imageCore: 'WW5VC45B;VV2KC2XD;7HXS56TV',
      statusContent: 'dot',
      hash: 'HY1BGVBR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HY1BGVBR'
    },
    {
      status: 210,
      sku: 'PM000138778',
      batchNumber: '6319973',
      btch_manufacturer: 14143282,
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 1500,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: '72XVWL31;8LE5NRGD',
      statusContent: 'dot',
      hash: 'T1V79LE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1V79LE5'
    },
    {
      status: 210,
      sku: 'PM000138779',
      batchNumber: '6319974',
      btch_manufacturer: 14143282,
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 1000,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 9,
      imageCore:
        'H5G9RV84;BTHBETTH;KCY75R2C;NPD3SJ7W;8CEREESJ;4KKBZ8BJ;BEW6954T;7JL41XGG;DBZYSYV8',
      statusContent: 'dot',
      hash: 'THRED9LH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THRED9LH'
    },
    {
      status: 210,
      sku: 'PM000138780',
      batchNumber: '6319975',
      btch_manufacturer: 14143282,
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 1000,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 16,
      imageCore:
        'BCNXSE62;KPW67AX9;2JW8P8KN;CC4BEJ3K;YH68XLCG;L7WDWY16;JN15ER2Y;ELNCTZCP;5W5KSGEL;HPXWRWYB;CR7RGWNN;HGP39817;XLXDV9HX;SZPWTHYV;ZCTR6KCT;RPCH7NXX',
      statusContent: 'dot',
      hash: 'WATZYZK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WATZYZK9'
    },
    {
      status: 210,
      sku: 'PM000138781',
      batchNumber: '6319977',
      btch_manufacturer: 14143282,
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 1500,
      sppl_prcp: 2.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 5,
      imageCore: 'P9TYJLCV;L9ETZPNY;5H3LH1PZ;JLA6VN27;HAN9HDV4',
      statusContent: 'dot',
      hash: '6T39PWKA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6T39PWKA'
    },
    {
      status: 210,
      sku: 'PM000138782',
      batchNumber: '6319980',
      btch_manufacturer: 14143282,
      name_search: 'WEFFPURP',
      name: "Weigela florida 'Foliis Purpureis'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 1040,
      sppl_prcp: 2.81,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: 'GNWS71GH;R2GP11NY',
      statusContent: 'dot',
      hash: '6RSYBPX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RSYBPX2'
    },
    {
      status: 210,
      sku: 'PM000138783',
      batchNumber: '6319981',
      btch_manufacturer: 14143282,
      name_search: 'WEFNPURP',
      name: "Weigela florida 'Nana Purpurea'",
      rng_range_identifier: 'C3',
      sppl_size_code: 'LEVC3',
      sppl_stock_available: 490,
      sppl_prcp: 2.81,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: '1WE1TDBR;85B1KBR9',
      statusContent: 'dot',
      hash: 'YKA4ACG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKA4ACG7'
    },
    {
      status: 210,
      sku: 'PM000138784',
      batchNumber: '6319982',
      btch_manufacturer: 14143282,
      name_search: 'ACFPVARI',
      name: "Achillea filipendulina 'Parker's Variety'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 534,
      sppl_order_minimum: 3,
      sppl_prcp: 0.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'PB5C9CVS;ZEPT8XCY;9J2T4WEE;98GY92T4',
      statusContent: 'dot',
      hash: 'AZ1GL651',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZ1GL651'
    },
    {
      status: 210,
      sku: 'PM000138785',
      batchNumber: '6319983',
      btch_manufacturer: 14143282,
      name_search: 'ACMCQUEE',
      name: "Achillea millefolium 'Cerise Queen'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 712,
      sppl_order_minimum: 3,
      sppl_prcp: 0.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'EYLR6AX9;4HEJRJ7Y;DL6HK49R;7KABHEN9',
      statusContent: 'dot',
      hash: 'THZJC3P6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THZJC3P6'
    },
    {
      status: 210,
      sku: 'PM000138786',
      batchNumber: '6319993',
      btch_manufacturer: 14143282,
      name_search: 'BRMEDIA',
      name: 'Briza media',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 712,
      sppl_order_minimum: 3,
      sppl_prcp: 0.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'HRJZKKTA;KG9BSAT9;VX9CC9L5;4D3BKBLR',
      statusContent: 'dot',
      hash: 'VVA6LBWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVA6LBWH'
    },
    {
      status: 210,
      sku: 'PM000138787',
      batchNumber: '6319994',
      btch_manufacturer: 14143282,
      name_search: 'CANNEPET',
      name: 'Calamintha nepeta nepeta',
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 3,
      imageCore: 'K92KWNR4;H6CBZZZN;A7RXW34B',
      statusContent: 'dot',
      hash: 'Z2KVN6Z9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2KVN6Z9'
    },
    {
      status: 210,
      sku: 'PM000138788',
      batchNumber: '6319998',
      btch_manufacturer: 14143282,
      name_search: 'CERROSEN',
      name: "Centranthus ruber 'Rosenrot'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 356,
      sppl_order_minimum: 3,
      sppl_prcp: 0.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J5W7BCJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5W7BCJL'
    },
    {
      status: 210,
      sku: 'PM000138789',
      batchNumber: '6319999',
      btch_manufacturer: 14143282,
      name_search: 'CETSILBE',
      name: "Cerastium tomentosum 'Silberteppich'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 356,
      sppl_order_minimum: 3,
      sppl_prcp: 0.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'YGHADL7T',
      statusContent: 'dot',
      hash: 'DNJZ9SWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DNJZ9SWS'
    },
    {
      status: 210,
      sku: 'PM000138790',
      batchNumber: '6320003',
      btch_manufacturer: 14143282,
      name_search: 'COGPREST',
      name: "Coreopsis grandiflora 'Presto'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'LEVP9',
      sppl_stock_available: 840,
      sppl_order_minimum: 3,
      sppl_prcp: 0.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 5,
      imageCore: 'DLASPV3V;XRX3E8RE;67XTV5TX;6K7734NW;DN7D2TWB',
      statusContent: 'dot',
      hash: 'PZ73GC9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ73GC9C'
    },
    {
      status: 990,
      sku: 'PM000096273',
      batchNumber: '5515431',
      btch_manufacturer: 1439,
      name_search: 'TIPLATYP',
      name: 'Tilia platyphyllos',
      rng_range_identifier: 'SHI025030RBWIR',
      sppl_size_code: '025030HODKL',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HTP82XNW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HTP82XNW'
    },
    {
      status: 910,
      sku: 'PM000138791',
      batchNumber: '6320010',
      btch_manufacturer: 14143422,
      name_search: 'BUDRDPLA',
      name: 'Buddleja davidii Reve de Papillon Lavender',
      rng_range_identifier: 'C10',
      sppl_size_code: '050060C10',
      sppl_stock_available: 1,
      sppl_prcp: 8.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RN91Z4ZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RN91Z4ZR'
    },
    {
      status: 910,
      sku: 'PM000138792',
      batchNumber: '6320045',
      btch_manufacturer: 1585,
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      rng_range_identifier: 'STHI016018',
      sppl_size_code: '016018HOCO',
      sppl_stock_available: 2,
      sppl_prcp: 708.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 31,
      imageCore:
        'ATWJA5YX;PBBNSSHL;8ER82CXB;Z39LS9WJ;X7T1K5XG;H4ZGWNHV;SZ2ASBE5;RVRLZA8T;LZG4XS5H;S6TDK6P3;4V1WWD7Y;Y5E6JLE8;DTVZZNRA;A2CXK5HA;N8EAJ1C4;RH7VYVLX;N9T55X2H;4RBD35CC;GCLAXEE5;E27ADHGA;P8ADDY5N;CCAX6XNS;E1J92YKJ;DZXH1JHH;S1XPCJ62;95BJH4ZV;EDVS1GL2;CAJ6YYBT;NLR2LH27;4Z4H8BC2;VKJLB9ZY',
      statusContent: 'dot',
      hash: 'Y2SXY73X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2SXY73X'
    },
    {
      status: 990,
      sku: 'PM000012980',
      batchNumber: '5829792',
      btch_manufacturer: 22,
      name_search: 'RUIWILLA',
      name: "Rubus idaeus 'Willamette'",
      rng_range_identifier: 'P12',
      sppl_size_code: 'PLGP12',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SNEWSART',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SNEWSART'
    },
    {
      status: 990,
      sku: 'PM000012981',
      batchNumber: '5829793',
      btch_manufacturer: 22,
      name_search: 'VACBLUEG',
      name: "Vaccinium corymbosum 'Bluegold'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: '78Z2GENH',
      statusContent: 'dot',
      hash: 'ZTT2XCAE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZTT2XCAE'
    },
    {
      status: 990,
      sku: 'PM000012982',
      batchNumber: '5829794',
      btch_manufacturer: 22,
      name_search: 'VACDARRO',
      name: "Vaccinium corymbosum 'Darrow'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: '68NL16A7',
      statusContent: 'dot',
      hash: '4Y8TLLXX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4Y8TLLXX'
    },
    {
      status: 990,
      sku: 'PM000012983',
      batchNumber: '5829796',
      btch_manufacturer: 22,
      name_search: 'EXMSPRIN',
      name: "Exochorda 'Magical Springtime'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7333B6XL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7333B6XL'
    },
    {
      status: 990,
      sku: 'PM000012984',
      batchNumber: '5829797',
      btch_manufacturer: 22,
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 6,
      imageCore: 'C8J6YEDT;LNBVN288;APR1TR92;89EPV848;PNYW4XY2;Y8RV7HL9',
      statusContent: 'dot',
      hash: 'TC6JJ1GR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TC6JJ1GR'
    },
    {
      status: 990,
      sku: 'PM000012987',
      batchNumber: '5829800',
      btch_manufacturer: 22,
      name_search: 'HAIRGLOW',
      name: "Hamamelis intermedia 'Ruby Glow'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: '8HV46938',
      statusContent: 'dot',
      hash: 'AA4S6CX7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AA4S6CX7'
    },
    {
      status: 990,
      sku: 'PM000012989',
      batchNumber: '5829802',
      btch_manufacturer: 22,
      name_search: 'HISPCHIF',
      name: 'Hibiscus syriacus Pink Chiffon',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: 'TBNJX7EB;6K9PNK8X;XB9SB1EH;TDEAAG8R',
      statusContent: 'dot',
      hash: 'AYJ4JJP9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AYJ4JJP9'
    },
    {
      status: 990,
      sku: 'PM000012991',
      batchNumber: '5829805',
      btch_manufacturer: 22,
      name_search: 'HYMMGRAN',
      name: "Hydrangea macrophylla 'Mariesii Grandiflora'",
      rng_range_identifier: 'P12',
      sppl_size_code: 'PLGP12',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D1N93PSX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D1N93PSX'
    },
    {
      status: 990,
      sku: 'PM000012992',
      batchNumber: '5829806',
      btch_manufacturer: 22,
      name_search: 'HYMTAUBE',
      name: "Hydrangea macrophylla 'Taube'",
      rng_range_identifier: 'P12',
      sppl_size_code: 'PLGP12',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 14,
      imageCore:
        'AV8N7GLG;75ZGD6ES;VJ665SRR;8PY8J2LV;W26N1CGH;YSL8VEG6;RJNHNNY1;K842167Z;NKKSHG5B;RGWN4BXV;HWCV72AA;DXKDTDVP;Z5EDHVEX;6972GNPS',
      statusContent: 'dot',
      hash: '8HLEXW6J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8HLEXW6J'
    },
    {
      status: 990,
      sku: 'PM000012993',
      batchNumber: '5829807',
      btch_manufacturer: 22,
      name_search: 'HYPCONFE',
      name: 'Hydrangea paniculata Confetti',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: '5W6CV8PW;GV81SK3G',
      statusContent: 'dot',
      hash: 'V6NNSCE5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V6NNSCE5'
    },
    {
      status: 990,
      sku: 'PM000012996',
      batchNumber: '5829810',
      btch_manufacturer: 22,
      name_search: 'HYPGRAFF',
      name: 'Hydrangea paniculata Graffiti',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZE44YJG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CZE44YJG'
    },
    {
      status: 990,
      sku: 'PM000012997',
      batchNumber: '5829811',
      btch_manufacturer: 22,
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 9,
      imageCore:
        'LW13XVKB;HG4N598J;NNN4RJYZ;DYXXXNRL;Z8RS18WP;5VGGGBAR;SVWA31TP;E7A5TPTX;DNG24REN',
      statusContent: 'dot',
      hash: 'H89DV42R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H89DV42R'
    },
    {
      status: 990,
      sku: 'PM000012998',
      batchNumber: '5829812',
      btch_manufacturer: 22,
      name_search: 'HYPLSPOO',
      name: 'Hydrangea paniculata Little Spooky',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 7,
      imageCore:
        '1P2BVKS6;VW15KDPN;A34KTXH6;KDZ6J5GB;Y95JGDWZ;VEGCP1SD;KJ2XZCA6',
      statusContent: 'dot',
      hash: 'YDVR4LK8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YDVR4LK8'
    },
    {
      status: 990,
      sku: 'PM000012999',
      batchNumber: '5829814',
      btch_manufacturer: 22,
      name_search: 'HYPSKYFA',
      name: 'Hydrangea paniculata Skyfall',
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 6,
      imageCore: 'ZR64W4CD;G5T5G34E;849C9R7X;76BJECKK;T3JP2PW7;E54N2JG1',
      statusContent: 'dot',
      hash: 'K2DRKGWW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K2DRKGWW'
    },
    {
      status: 990,
      sku: 'PM000013014',
      batchNumber: '5829829',
      btch_manufacturer: 22,
      name_search: 'MAMWSUN',
      name: "Mahonia media 'Winter Sun'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 1.195,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 7,
      imageCore:
        '8N579SSZ;G1V8LNEJ;K61DG9CX;RBKBAV1S;B4CK3P3Z;VH2HHD6A;4PA4Z45X',
      statusContent: 'dot',
      hash: 'C78K87DZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C78K87DZ'
    },
    {
      status: 990,
      sku: 'PM000013088',
      batchNumber: '5829909',
      btch_manufacturer: 22,
      name_search: 'CEJAPONI',
      name: 'Cercidiphyllum japonicum',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 3,
      imageCore: 'C9963LKG;6JBLHWLR;B54A7HVW',
      statusContent: 'dot',
      hash: 'XTNSZTGP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XTNSZTGP'
    },
    {
      status: 990,
      sku: 'PM000013036',
      batchNumber: '5829855',
      btch_manufacturer: 22,
      name_search: 'SYCSAUGE',
      name: "Syringa chinensis 'Saugeana'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 1.041,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A4PNER6S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A4PNER6S'
    },
    {
      status: 990,
      sku: 'PM000013081',
      batchNumber: '5829902',
      btch_manufacturer: 22,
      name_search: 'BERTRSTA',
      name: "Berberis thunbergii 'Ruby Star'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 1.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GN1SYP5P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GN1SYP5P'
    },
    {
      status: 990,
      sku: 'PM000013013',
      batchNumber: '5829828',
      btch_manufacturer: 22,
      name_search: 'MAMCHARI',
      name: "Mahonia media 'Charity'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 1.195,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'W67TNLBZ',
      statusContent: 'dot',
      hash: 'AV7HHTH1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AV7HHTH1'
    },
    {
      status: 990,
      sku: 'PM000013009',
      batchNumber: '5829824',
      btch_manufacturer: 22,
      name_search: 'ILMKANEH',
      name: 'Ilex maximowicziana kanehirae',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 9,
      imageCore:
        'H524A5ZD;SXBSEXA7;TB4C2YJH;CYD5RSNA;GY3TVEE9;9VXSB8H1;HZZK1LRY;N8ALKLZW;BDZ2V5LA',
      statusContent: 'dot',
      hash: 'W8BPWP3S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W8BPWP3S'
    },
    {
      status: 910,
      sku: 'PM000138793',
      batchNumber: '6320046',
      btch_manufacturer: 1585,
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      rng_range_identifier: 'STHI016018',
      sppl_size_code: '016018HOCO',
      sppl_stock_available: 2,
      sppl_prcp: 386.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 6,
      imageCore: 'PAKDRLWJ;AWDTRCYB;8EXX7T9B;5AR4L4SC;JYN6SZ95;1B2W9GTE',
      statusContent: 'dot',
      hash: 'VVE42G5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVE42G5C'
    },
    {
      status: 990,
      sku: 'PM000096274',
      batchNumber: '5515432',
      btch_manufacturer: 1439,
      name_search: 'TSCANADE',
      name: 'Tsuga canadensis',
      rng_range_identifier: 'SPECH200250RBWIR',
      sppl_size_code: '200250DKLSO',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 3,
      imageCore: 'DCD8J3AX;6ZBT3R36;H149TLGX',
      statusContent: 'dot',
      hash: 'CR1VEVG5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CR1VEVG5'
    },
    {
      status: 990,
      sku: 'PM000013002',
      batchNumber: '5829817',
      btch_manufacturer: 22,
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 1.453,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: '76VRKW66;SWAER5PX;476SSB9S;8VTTZVCG',
      statusContent: 'dot',
      hash: 'CX6T4TB5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CX6T4TB5'
    },
    {
      status: 990,
      sku: 'PM000013003',
      batchNumber: '5829818',
      btch_manufacturer: 22,
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 11,
      imageCore:
        'DBZ6WP34;Y6KGSK9X;4EB246P3;LYPHCD6L;SRHDJK6A;A6DVG744;VXBTHXD5;ELGB7SNT;EA6R9HBD;CC2EAPDG;CTB35BRP',
      statusContent: 'dot',
      hash: 'XRDW95NX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XRDW95NX'
    },
    {
      status: 990,
      sku: 'PM000013006',
      batchNumber: '5829821',
      btch_manufacturer: 22,
      name_search: 'ILCFASTI',
      name: "Ilex crenata 'Fastigiata'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'D6RNTX8N',
      statusContent: 'dot',
      hash: 'EAKCEVAN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EAKCEVAN'
    },
    {
      status: 990,
      sku: 'PM000013010',
      batchNumber: '5829825',
      btch_manufacturer: 22,
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 9,
      imageCore:
        'Z15D3Y59;18PPZPXY;A66RZH3S;L59ES8JD;1PL2ARDA;GH6WT46H;2XX4L7XC;AB5YDBPJ;9K1X5PH6',
      statusContent: 'dot',
      hash: '8K231DN5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8K231DN5'
    },
    {
      status: 990,
      sku: 'PM000013032',
      sppl_ean: '8720626370982',
      batchNumber: '5829851',
      btch_manufacturer: 22,
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      rng_range_identifier: 'C2',
      sppl_size_code: 'LEVC2',
      sppl_stock_available: 0,
      sppl_prcp: 2.926,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'APTT1GC2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'APTT1GC2'
    },
    {
      status: 990,
      sku: 'PM000013076',
      batchNumber: '5829896',
      btch_manufacturer: 22,
      name_search: 'BETLSTAR',
      name: "Berberis thunbergii 'Lime Star'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'PH1AS2EB',
      statusContent: 'dot',
      hash: '88E6LP8P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '88E6LP8P'
    },
    {
      status: 990,
      sku: 'PM000013015',
      batchNumber: '5829831',
      btch_manufacturer: 22,
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      rng_range_identifier: 'P12',
      sppl_size_code: 'PLGP12',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 8,
      imageCore:
        'JBYSDD84;H2SBSR7C;Y9GJXZA1;EZR46TXP;5KYPTXPW;5CATHPGB;VAHHVD4K;9TNLZG2S',
      statusContent: 'dot',
      hash: 'N6YHK432',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N6YHK432'
    },
    {
      status: 990,
      sku: 'PM000013016',
      batchNumber: '5829832',
      btch_manufacturer: 22,
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      rng_range_identifier: 'C2',
      sppl_size_code: 'LEVC2',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 26,
      imageCore:
        'T789TK9B;LSW45PBT;B8G3NHDD;DKZL4YYE;NL6P24X5;VV6RYAER;BC5Z44WR;5NGPXD4C;JHE8N7GE;DNHR55KY;81KZ2LAV;7JWSDNK3;EED676ZB;K1RCSDTT;E2N3N285;HZ4CH5XP;67BLPL18;Z3638ESY;7VJLLGK4;XG779P8Y;LJALGBLS;VK62VEAZ;XTNZKHYD;KCV3TB1N;9ZPCWDN6;SEBGNLR7',
      statusContent: 'dot',
      hash: '2TN8DJBD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2TN8DJBD'
    },
    {
      status: 990,
      sku: 'PM000013020',
      batchNumber: '5829839',
      btch_manufacturer: 22,
      name_search: 'PIJLHEAT',
      name: "Pieris japonica 'Little Heath'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 15,
      imageCore:
        'EBW6GC4B;66JRW58L;55T78A11;ZY65NZ9H;XSERJ1RA;55CR987A;AC6L31ED;XVRSXA3X;XJSR9T1L;HGZVW7E2;H9Z1XYDH;K74AV2DV;Z5WACGTB;855RWEH4;9L7KZ59D',
      statusContent: 'dot',
      hash: 'LZ3W2J81',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LZ3W2J81'
    },
    {
      status: 990,
      sku: 'PM000013022',
      batchNumber: '5829841',
      btch_manufacturer: 22,
      name_search: 'POFNDAWN',
      name: "Potentilla fruticosa 'New Dawn'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: 'TZ5GYRJT;D8RSKZ1H',
      statusContent: 'dot',
      hash: 'D6ZC18ZX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D6ZC18ZX'
    },
    {
      status: 990,
      sku: 'PM000013023',
      batchNumber: '5829842',
      btch_manufacturer: 22,
      name_search: 'POFOSTAR',
      name: "Potentilla fruticosa 'Orange Star'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '96YASTLJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '96YASTLJ'
    },
    {
      status: 990,
      sku: 'PM000013025',
      batchNumber: '5829844',
      btch_manufacturer: 22,
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: '72XVWL31;8LE5NRGD',
      statusContent: 'dot',
      hash: '2N3CW9W3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2N3CW9W3'
    },
    {
      status: 990,
      sku: 'PM000013026',
      batchNumber: '5829845',
      btch_manufacturer: 22,
      name_search: 'PYOGLOW',
      name: "Pyracantha 'Orange Glow'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: 'W3YA9VXY;7RT5DE3Z',
      statusContent: 'dot',
      hash: '5GBCB4ZE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5GBCB4ZE'
    },
    {
      status: 990,
      sku: 'PM000013027',
      batchNumber: '5829846',
      btch_manufacturer: 22,
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 12,
      imageCore:
        'NJK5XKHA;6EZLP1AC;S3EVKBTC;N1S3JVJN;BAVPHXD4;9XWKLWG8;KENE19KY;ZBGB61CD;S9LEWV3L;D7CS513L;X1E8LJDH;JW1AWSC1',
      statusContent: 'dot',
      hash: 'SXBNR4JN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SXBNR4JN'
    },
    {
      status: 990,
      sku: 'PM000013029',
      batchNumber: '5829848',
      btch_manufacturer: 22,
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 17,
      imageCore:
        '4XWAJPWJ;19XSBG34;W7SWGZ3T;4KLK856R;6E3PT1NG;5CNV339D;2EAS2ARD;RAS5S9R9;CXH6L2BK;S9K5E9JX;22VLANC2;G59CDWND;L295EV58;7XKN6G4N;PRY64SC1;S235W643;B1WR1BJ8',
      statusContent: 'dot',
      hash: 'HJX76LTH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HJX76LTH'
    },
    {
      status: 990,
      sku: 'PM000013030',
      batchNumber: '5829849',
      btch_manufacturer: 22,
      name_search: 'SACONFUS',
      name: 'Sarcococca confusa',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 18,
      imageCore:
        'HDG8R8SL;4YVYD5YW;EC81AW6S;8WK21XBH;69RZDKWN;LD92KA6T;V2R48W19;L8PZWT9H;D43YJE5C;Z63JPTGE;XAVJDPKE;X9LNEXAB;YHCNDZJV;1H36L4Y6;4WPX8KER;CJZ9P22R;42X1L3A5;TWDVBT76',
      statusContent: 'dot',
      hash: 'C71HPJXN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C71HPJXN'
    },
    {
      status: 990,
      sku: 'PM000013035',
      batchNumber: '5829854',
      btch_manufacturer: 22,
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: '568H2CJB;BNT7ZYNB;V4PKJCBW;L7CS1VXR',
      statusContent: 'dot',
      hash: 'GDBLS32E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GDBLS32E'
    },
    {
      status: 990,
      sku: 'PM000013039',
      batchNumber: '5829858',
      btch_manufacturer: 22,
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 11,
      imageCore:
        'AVEYGY8L;SBWA4CVG;STCHC9PX;EE1BKY42;C56GAG7S;HP7RBWG3;N4KRZVPV;E733E74P;AAEWDW42;GRS76H2X;PEKNJD5D',
      statusContent: 'dot',
      hash: '5DE78BVK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5DE78BVK'
    },
    {
      status: 990,
      sku: 'PM000013045',
      batchNumber: '5829864',
      btch_manufacturer: 22,
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 19,
      imageCore:
        '7L61B3EP;GXEA1CR1;N1Y4TYSK;6V72HX6Z;TJ3CR11T;6X8GK8AR;JBY6AYSR;EYC29SDC;XCZGSYXZ;S5LBV8LL;YNY2ETLH;A2LT2L3N;795TKV88;RA5K5D21;54R7AB6H;9233J6P1;7A79NYG8;6CZV6PCW;WYBP7VXV',
      statusContent: 'dot',
      hash: 'ECVGXKYW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ECVGXKYW'
    },
    {
      status: 990,
      sku: 'PM000013046',
      batchNumber: '5829865',
      btch_manufacturer: 22,
      name_search: 'VIPCASCA',
      name: "Viburnum plicatum 'Cascade'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 2,
      imageCore: 'SBPB7L33;LRE1ETBS',
      statusContent: 'dot',
      hash: '6A897W74',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6A897W74'
    },
    {
      status: 990,
      sku: 'PM000013047',
      batchNumber: '5829866',
      btch_manufacturer: 22,
      name_search: 'VIMVARIE',
      name: "Vinca major 'Variegata'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 5,
      imageCore: 'H2RTVZJG;6JKNDCDC;RB9GZ42H;173KCCSS;5CDLLNYE',
      statusContent: 'dot',
      hash: '553ZTXHT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '553ZTXHT'
    },
    {
      status: 990,
      sku: 'PM000013048',
      batchNumber: '5829867',
      btch_manufacturer: 22,
      name_search: 'VIMATROP',
      name: "Vinca minor 'Atropurpurea'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 9,
      imageCore:
        'LWRS6SGG;J9XLG826;BDYL7YWS;EY18SB9S;RSY6BG55;XZR6Z63B;NV28HLT2;N53J9H45;ZD54P9WY',
      statusContent: 'dot',
      hash: 'D9894Z4Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D9894Z4Z'
    },
    {
      status: 990,
      sku: 'PM000013049',
      batchNumber: '5829868',
      btch_manufacturer: 22,
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 4,
      imageCore: '9PKPTDCT;HAJ87GY9;87N5BLY8;5YC9R9GZ',
      statusContent: 'dot',
      hash: 'CNBHLLYL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CNBHLLYL'
    },
    {
      status: 990,
      sku: 'PM000013050',
      batchNumber: '5829869',
      btch_manufacturer: 22,
      name_search: 'CHPBBLUE',
      name: "Chamaecyparis pisifera 'Baby Blue'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 7,
      imageCore:
        '8B8GBXT3;8GJZVZP2;PYDRZTET;55BXWR1J;DTARZCDB;X191DE6R;X64ZNZ6E',
      statusContent: 'dot',
      hash: 'TN4KLSV9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TN4KLSV9'
    },
    {
      status: 990,
      sku: 'PM000013052',
      batchNumber: '5829871',
      btch_manufacturer: 22,
      name_search: 'JUCGCARP',
      name: "Juniperus communis 'Green Carpet'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 19,
      imageCore:
        'ZK78DYWX;8V7A4V7B;5AS7P1CN;2ASKZE8W;2B9LRAY3;NBTXYG17;DYAT5591;THT5A955;HXC79VS9;DV391312;9TDNBYT3;LW9L5HYB;1SJ6T9JX;S3AWLEJ5;VV8PAJNW;AH5CN41A;KZ1N314W;TTP1PDTP;6DTXKST6',
      statusContent: 'dot',
      hash: 'AN9NP1D5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AN9NP1D5'
    },
    {
      status: 990,
      sku: 'PM000013056',
      batchNumber: '5829875',
      btch_manufacturer: 22,
      name_search: 'PICGDECE',
      name: "Picea glauca 'December'",
      rng_range_identifier: 'C2',
      sppl_size_code: 'LEVC2',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PG7939Z8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PG7939Z8'
    },
    {
      status: 990,
      sku: 'PM000013057',
      batchNumber: '5829876',
      btch_manufacturer: 22,
      name_search: 'PIPLSTRI',
      name: "Picea pungens 'Lucky Strike'",
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'P949G3DJ',
      statusContent: 'dot',
      hash: 'Y64YSA6K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Y64YSA6K'
    },
    {
      status: 990,
      sku: 'PM000013058',
      batchNumber: '5829877',
      btch_manufacturer: 22,
      name_search: 'PIPSBSEE',
      name: 'Picea pungens Super Blue Seedling',
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EPEW4LV2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EPEW4LV2'
    },
    {
      status: 990,
      sku: 'PM000013059',
      batchNumber: '5829878',
      btch_manufacturer: 22,
      name_search: 'PISSCHUC',
      name: "Pinus sylvestris 'Schuch'",
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YHLZC7KZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YHLZC7KZ'
    },
    {
      status: 990,
      sku: 'PM000013060',
      batchNumber: '5829879',
      btch_manufacturer: 22,
      name_search: 'PITMAIJI',
      name: "Pinus thunbergii 'Maijima'",
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '41ZZ483Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '41ZZ483Y'
    },
    {
      status: 990,
      sku: 'PM000013061',
      batchNumber: '5829880',
      btch_manufacturer: 22,
      name_search: 'PITSAYON',
      name: "Pinus thunbergii 'Sayonara'",
      rng_range_identifier: 'C1.5',
      sppl_size_code: 'LEVC14',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A3NYHNSX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A3NYHNSX'
    },
    {
      status: 990,
      sku: 'PM000013063',
      batchNumber: '5829882',
      btch_manufacturer: 22,
      name_search: 'THODLIGH',
      name: "Thuja occidentalis 'Dawid Light'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G9DTS7V7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'G9DTS7V7'
    },
    {
      status: 990,
      sku: 'PM000013064',
      batchNumber: '5829883',
      btch_manufacturer: 22,
      name_search: 'THOCCIDE',
      name: 'Thuja occidentalis',
      rng_range_identifier: 'C2',
      sppl_size_code: 'LEVC2',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2YCL8CTH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2YCL8CTH'
    },
    {
      status: 990,
      sku: 'PM000013065',
      batchNumber: '5829884',
      btch_manufacturer: 22,
      name_search: 'THOHOLMS',
      name: "Thuja occidentalis 'Holmstrup'",
      rng_range_identifier: 'C2',
      sppl_size_code: 'LEVC2',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 11,
      imageCore:
        'D6PCDDXY;8JEAPWEK;YTZY8BEW;N6R494PA;D6JBVEC8;P4X2PXY5;PV6N5XTY;4YGZCJEK;Y9ZA5PT2;73DV28ZR;Z186YRZ6',
      statusContent: 'dot',
      hash: 'L5YARDJL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L5YARDJL'
    },
    {
      status: 990,
      sku: 'PM000013066',
      batchNumber: '5829885',
      btch_manufacturer: 22,
      name_search: 'THOJOSKA',
      name: "Thuja occidentalis 'Jóska'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L1GP26S7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L1GP26S7'
    },
    {
      status: 990,
      sku: 'PM000013069',
      batchNumber: '5829888',
      btch_manufacturer: 22,
      name_search: 'TSHETERO',
      name: 'Tsuga heterophylla',
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 1,
      imageCore: 'YH5ZKV4S',
      statusContent: 'dot',
      hash: '1D5T3AYZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1D5T3AYZ'
    },
    {
      status: 990,
      sku: 'PM000013070',
      batchNumber: '5829889',
      btch_manufacturer: 22,
      name_search: 'ACKDSZYM',
      name: "Actinidia kolomikta 'Dr Szymanowski'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WVKAP8LT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WVKAP8LT'
    },
    {
      status: 990,
      sku: 'PM000013071',
      batchNumber: '5829890',
      btch_manufacturer: 22,
      name_search: 'BETADMIR',
      name: "Berberis thunbergii 'Admiration'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 17,
      imageCore:
        '6V8T7JL4;549NV92A;2L3K3PH3;Z15ATZKG;GX8WZL5K;B912YZ8D;7JBNRGVP;833B9PJK;KC2PXHZC;295CJEZ9;Y71B7798;PN13457X;XWZTZBX9;5V9D81AN;9V7G2JX8;TR5CXD3L;JBJZZK8Z',
      statusContent: 'dot',
      hash: 'TC3WD72G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TC3WD72G'
    },
    {
      status: 990,
      sku: 'PM000013073',
      batchNumber: '5829893',
      btch_manufacturer: 22,
      name_search: 'BETEVITA',
      name: "Berberis thunbergii 'Evita'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N71DZSJT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N71DZSJT'
    },
    {
      status: 990,
      sku: 'PM000013074',
      batchNumber: '5829894',
      btch_manufacturer: 22,
      name_search: 'BETGOLDA',
      name: "Berberis thunbergii 'Goldalita'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PVPRG9R4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PVPRG9R4'
    },
    {
      status: 990,
      sku: 'PM000013077',
      batchNumber: '5829897',
      btch_manufacturer: 22,
      name_search: 'BETMARIA',
      name: "Berberis thunbergii 'Maria'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCoreTotal: 5,
      imageCore: 'DAXJ3KCJ;WPYRVSRH;SJRYP4R1;YSKWCJN9;A2TP3JT3',
      statusContent: 'dot',
      hash: 'KBG5JZ73',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KBG5JZ73'
    },
    {
      status: 990,
      sku: 'PM000013078',
      batchNumber: '5829898',
      btch_manufacturer: 22,
      name_search: 'BETOCARP',
      name: "Berberis thunbergii 'Orange Carpet'",
      rng_range_identifier: 'P9',
      sppl_size_code: 'PLGP9',
      sppl_stock_available: 0,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVAB7HXV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SVAB7HXV'
    }
  ]
}

export default listWholesaleData
