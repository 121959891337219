import React, { useContext, useReducer, useEffect } from 'react'
import reducer from '../reducers/filter_reducer'
import { useListContext } from './list_context'
import listMappings from '../data/mappingData'
import dataFilter from '../data/filterData'

const initialState = {
  filteredResult: [],
  inputValuesFilters: [],
  mappedContent: [],
  filterDropdownVisible: false,
  filterDropdownWidth: 0,
  filterDropdownColumnName: '',
  advFilters: [],
  filterDropdownValues: [],
  filterDropdownSelections: [],
  isAdvFilterVisible: false,
  curListId: 'retail',
  columnName: ''
}

const FilterContext = React.createContext()

export const FilterProvider = ({ children }) => {
  const { listData } = useListContext()
  const { listHeaders, listRecords } = listData

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const inputValuesFilters = {}
    listHeaders.forEach(el => {
      inputValuesFilters[el] = ''
    })
    dispatch({
      type: 'SET_INPUT_VALUES_FILTERS',
      payload: inputValuesFilters
    })
    delayFunction(state.filterDropdownSelections, state.columnName)
  }, [listRecords, listHeaders])

  const setRouteId = id => {
    dispatch({
      type: 'SET_ROUTE_ID',
      payload: id
    })
  }

  const handleChangeFilters = e => {
    dispatch({
      type: 'HANDLE_CHANGE',
      stateProperty: e.target.name,
      payload: e.target.value
    })
    delayFunction(state.filterDropdownSelections, state.columnName)
  }

  const setMappingFunction = () => {
    if (state.curListId === 'wholesale')
      return listMappings.setProductWholesaleContent
    if (state.curListId === 'retail')
      return listMappings.setProductRetailContent
  }

  const mapListContent = curMappingFunction => {
    let mappedContent = []
    for (let j = 0; j < listRecords.length; j++) {
      let contentRow = {}
      for (let i = 0; i < listHeaders.length; i++)
        contentRow[listHeaders[i]] = curMappingFunction(listRecords, j)[i]
      mappedContent.push(contentRow)
    }
    return mappedContent
  }

  const setFilterDropdown = (title, filterDropdownWidth) => {
    const filterDropdownColumnName = title
    const allRowValuesArr = state.filteredResult.map(el => {
      return el[title]
    })
    allRowValuesArr.unshift('Select all')
    const allRowValuesSet = new Set(allRowValuesArr)
    const filterDropdownValues = Array.from(allRowValuesSet)
    const filterDropdownSelections = filterDropdownValues.map(el => {
      return (el = false)
    })
    dispatch({
      type: 'SET_FILTER_DROPDOWN',
      payload: {
        filterDropdownVisible: !state.filterDropdownVisible,
        filterDropdownWidth,
        filterDropdownValues,
        filterDropdownColumnName,
        filterDropdownSelections
      }
    })
  }

  const handleChangeFilterDropdown = (columnName, filterValue) => {
    const curIndex = state.filterDropdownValues.indexOf(filterValue, 0)
    const filterDropdownSelections = structuredClone(
      state.filterDropdownSelections
    )
    filterDropdownSelections[curIndex] = !filterDropdownSelections[curIndex]
    dispatch({
      type: 'HANDLE_CHANGE_FILTER_DROPDOWN',
      payload: { filterDropdownSelections, columnName }
    })
    delayFunction(filterDropdownSelections, columnName)
  }

  const delayFunction = (filterDropdownSelections, columnName) => {
    setTimeout(() => {
      setFilteredResultDelay(columnName, filterDropdownSelections)
    }, 2000)
  }

  const setFilteredResultDelay = (columnName, filterDropdownSelections) => {
    const curMappingFunction = setMappingFunction()
    const mappedContent = mapListContent(curMappingFunction)
    const newFilteredResult = setFilteredResult(
      Object.keys(state.inputValuesFilters),
      Object.values(state.inputValuesFilters),
      mappedContent
    )
    const filteredResult = setFilteredResultDropdowns(
      columnName,
      filterDropdownSelections,
      newFilteredResult
    )
    dispatch({
      type: 'SET_FILTERED_RESULT',
      payload: filteredResult
    })
  }

  const setFilteredResult = (filterNames, filterValues, mappedContent) => {
    const newMappedContent = structuredClone(mappedContent)
    for (let i = newMappedContent.length - 1; i >= 0; i--) {
      for (let j = 0; j < filterNames.length - 1; j++) {
        const contentValue = newMappedContent[i][filterNames[j]]
        if (
          filterValues[j].length > 0 &&
          (!contentValue || !contentValue.toString().includes(filterValues[j]))
        ) {
          newMappedContent.splice(i, 1)
          break
        }
      }
    }
    return newMappedContent
  }

  const setFilteredResultDropdowns = (
    columnName,
    filterDropdownSelections,
    mappedContent
  ) => {
    const newMappedContent = structuredClone(mappedContent)
    const selectedFilters = state.filterDropdownValues.filter(
      (el, i) => filterDropdownSelections[i] === true
    )
    if (selectedFilters.length > 0 && filterDropdownSelections[0] === false) {
      for (let i = newMappedContent.length - 1; i >= 0; i--) {
        let hasPassedFilterCheck = false
        selectedFilters.forEach(el => {
          if (newMappedContent[i][columnName] === el)
            hasPassedFilterCheck = true
        })
        if (!hasPassedFilterCheck) newMappedContent.splice(i, 1)
      }
    }
    return newMappedContent
  }

  const setAdvancedFilters = () => {
    dispatch({ type: 'SET_ADV_FILTERS', payload: dataFilter })
  }

  const toggleAdvFilters = () => {
    dispatch({
      type: 'TOGGLE_ADV_FILTERS',
      payload: !state.isAdvFilterVisible
    })
  }

  return (
    <FilterContext.Provider
      value={{
        ...state,
        handleChangeFilters,
        setFilterDropdown,
        toggleAdvFilters,
        setAdvancedFilters,
        setRouteId,
        handleChangeFilterDropdown
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterContext = () => {
  return useContext(FilterContext)
}
