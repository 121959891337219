const dataFilter = [
  'SKU retail',
  'EAN',
  'Name search',
  'Product name',
  'Plant group',
  'Article group',
  'Supplier size code',
  'Range Identifier',
  'Range description',
  'Stg',
  'Stock',
  'PRCP.R',
  'Factor',
  'Tax',
  'abbrShippingPoints',
  'Del.w',
  'C',
  'Category',
  'Article group supplier',
  'Img'
]

export default dataFilter
