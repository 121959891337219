import styled from 'styled-components'
import FilterElement from '../atoms/FilterElement'

const DashboardAdvFilter = props => {
  const { advFilters, isAdvFilterVisible } = props

  const listDataToDisplay = advFilters.map(el => (
    <FilterElement title={el} key={el} name={el} />
  ))

  return (
    <Wrapper isAdvFilterVisible={isAdvFilterVisible}>
      {listDataToDisplay}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid rgba(216, 217, 219, 1);
  background-color: rgba(231, 232, 233, 1);
  width: 100%;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 6px;
  padding: 6px;
  transition: all 3s;
  display: ${props => (props.isAdvFilterVisible ? 'grid' : 'none')};
  overflow: hidden;
`

export default DashboardAdvFilter
