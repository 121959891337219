import styled from 'styled-components'
import '../../App.css'

const HeaderInputIcon = props => {
  const { icon, dynamicStyle } = props
  return <Wrapper style={dynamicStyle}>{icon}</Wrapper>
}

export default HeaderInputIcon

const Wrapper = styled.div`
  color: rgba(113, 112, 116, 1);
  font-size: 20px;
  transition: all 0.3s;
  &:hover {
    color: rgba(172, 30, 40, 1);
    cursor: pointer;
  }
`
