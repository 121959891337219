import React, { useContext, useReducer, useEffect } from 'react'
import reducer from '../reducers/preferences_reducer'
import preferencesData from '../data/preferencesData'

const initialState = {
  isLoading: true,
  columnWidths: [],
  userLanguages: ['nl_NL', 'en_GB'],
  currentLanguage: 'en_GB'
}

const PreferencesContext = React.createContext()

export const PreferencesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (state.isLoading) {
      fetchListData()
    }
  }, [preferencesData])

  const fetchListData = () => {
    dispatch({ type: 'FETCH_PREFERENCESDATA_BEGIN' })
    try {
      dispatch({
        type: 'FETCH_PREFERENCESDATA_SUCCESS',
        payload: preferencesData
      })
    } catch (error) {
      console.log(error)
      dispatch({ type: 'FETCH_PREFERENCESDATA_ERROR' })
    }
  }

  const setLanguage = language => {
    console.log(language)
    dispatch({ type: 'SET_LANGUAGE', payload: language })
  }

  return (
    <PreferencesContext.Provider
      value={{
        ...state,
        fetchListData,
        setLanguage
      }}
    >
      {children}
    </PreferencesContext.Provider>
  )
}

export const usePreferencesContext = () => {
  return useContext(PreferencesContext)
}
