const header_reducer = (state, action) => {
  if (action.type === 'LOAD_DATA') {
    return {
      ...state,
      menuItems: action.payload.menuItems,
      versionNumber: action.payload.versionNumber,
      loginName: action.payload.loginName
    }
  }
  if (action.type === 'HANDLE_CHANGE') {
    return { ...state, [action.stateProperty]: action.payload }
  }
  if (action.type === 'TOGGLE_HEADER_DROPDOWN')
    return {
      ...state,
      headerDropdownVisible: action.payload
    }

  if (action.type === 'HANDLE_CHANGE_HEADER_DROPDOWN') {
    const { headerDropdownSelection } = action.payload
    return {
      ...state,
      headerDropdownSelection
    }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default header_reducer
