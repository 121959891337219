import React, { useContext, useReducer } from 'react'
import reducer from '../reducers/sort_reducer'

const initialState = {
  sorted_result: []
}

const SortContext = React.createContext()

export const SortProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <SortContext.Provider
      value={{
        ...state
      }}
    >
      {children}
    </SortContext.Provider>
  )
}

export const useSortContext = () => {
  return useContext(SortContext)
}
