import styled from 'styled-components'
import imageSrcs from '../../utils/languageImages'
import { usePreferencesContext } from '../../contexts/preferences_context'

const LanguageBar = () => {
  const {
    userLanguages,
    currentLanguage,
    setLanguage
  } = usePreferencesContext()
  const languageImagesToDisplay = []

  for (const [key, value] of Object.entries(imageSrcs)) {
    userLanguages.forEach(el => {
      if (key === el) {
        languageImagesToDisplay.push(
          <img
            key={key}
            src={value}
            alt={`language ${key}`}
            onClick={() => setLanguage(key)}
            className={
              key === currentLanguage ? 'image' : 'image image-not-selected'
            }
          />
        )
      }
    })
  }

  return <Wrapper>{languageImagesToDisplay}</Wrapper>
}

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content;
  grid-gap: 3px;
  border: 1px solid rgba(216, 217, 219, 1);
  background-color: rgba(231, 232, 233, 1);
  justify-content: end;

  .image-not-selected {
    opacity: 0.4;
  }

  .image {
    width: 20px;
    max-width: 100%;
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
`

export default LanguageBar
