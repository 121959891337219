import React, { useContext, useReducer } from 'react'
import reducer from '../reducers/login_reducer'

const initialState = {
  userType: 'Manager',
  currentLoginStep: 3,
  username: '',
  password: '',
  qrTimedOut: true
}

const LoginContext = React.createContext()

export const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const nextLoginStep = inputToValidate => {
    const isLengthValid = validateInputLength(inputToValidate)
    const areCharsValid = validateInputChars(inputToValidate)
    if (isLengthValid && areCharsValid)
      dispatch({ type: 'NEXT_LOGIN_STEP', payload: state.currentLoginStep + 2 })
  }

  const validateInputLength = inputToValidate => {
    if (inputToValidate.length > 0) return true
    return false
  }

  const validateInputChars = inputToValidate => {
    return /^[A-Za-z0-9 -@]*$/.test(inputToValidate)
  }

  const prevLoginStep = () => {
    dispatch({
      type: 'PREVIOUS_LOGIN_STEP',
      payload: state.currentLoginStep - 1
    })
  }

  const handleChange = e => {
    dispatch({
      type: 'HANDLE_CHANGE',
      stateProperty: e.target.name,
      payload: e.target.value
    })
  }

  return (
    <LoginContext.Provider
      value={{
        ...state,
        nextLoginStep,
        prevLoginStep,
        handleChange
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

export const useLoginContext = () => {
  return useContext(LoginContext)
}
