import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { VscClose } from 'react-icons/vsc'
import FooterIcon from '../atoms/FooterIcon'

const DashboardMenu = props => {
  const {
    submenuData,
    curSubmenuName,
    subMenuVisible,
    toggleVisibility
  } = props

  const curSubMenu = submenuData.map(el => (
    <div className='menu-group' key={el.title}>
      <h3 className='menu-h3'>{el.title}</h3>
      {el.items.map(innerEl => (
        <NavLink
          className='menu-item'
          key={innerEl.name}
          to={`/dashboard/${innerEl.id}/`}
        >
          {innerEl.name}
        </NavLink>
      ))}
    </div>
  ))

  const subMenuItemsLeft = curSubMenu.filter((el, i) => i % 2 !== 0)
  const subMenuItemsRight = curSubMenu.filter((el, i) => i % 2 === 0)

  return (
    <Wrapper subMenuVisible={subMenuVisible}>
      <h1 className='color-white text-transform'>
        {curSubmenuName.toLowerCase()}
      </h1>
      <div className='submenu-title'>
        <FooterIcon
          clicked={toggleVisibility}
          icon={<VscClose className='icon' size={'24px'} />}
        />
      </div>
      <div>{subMenuItemsLeft}</div>
      <div>{subMenuItemsRight}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: rgba(255, 255, 255, 1);
  background-color: rgba(68, 67, 66, 1);
  box-shadow: 0 0 2px 0 rgb(0 0 0);
  z-index: 100;
  position: absolute;
  padding: 16px 96px 96px 16px;
  grid-gap: 10px;
  transition: all 0.2s;
  transition-timing-function: ease-in-out;
  right: ${props => (props.subMenuVisible ? '-20px' : '-620px')};

  .submenu-title {
    display: flex;
    justify-content: end;
    margin-right: 16px;
  }

  .menu-group {
    width: 280px;
    display: grid;
    height: max-content;
    margin-bottom: 24px;
  }

  .icon {
    transition: all 0.2s;
    cursor: pointer;
  }

  .menu-item {
    padding: 6px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.2s;
    font-size: 15px;
    text-decoration: none;
    &:hover {
      background: rgba(45, 42, 38, 1);
    }
  }

  .menu-h3 {
    padding-left: 6px;
    color: rgba(161, 160, 164, 1);
  }

  .text-transform {
    text-transform: capitalize;
  }
`

export default DashboardMenu
