const submenu_reducer = (state, action) => {
  if (action.type === 'LOAD_DATA') {
    return {
      ...state,
      menuItems: action.payload.menuItems,
      versionNumber: action.payload.versionNumber,
      loginName: action.payload.loginName
    }
  }
  if (action.type === 'HANDLE_CHANGE') {
    return { ...state, [action.stateProperty]: action.payload }
  }
  if (action.type === 'TOGGLE_VISIBILITY_ON') {
    const { submenuData, subMenuVisible, curSubmenuName } = action.payload
    return { ...state, submenuData, subMenuVisible, curSubmenuName }
  }
  if (action.type === 'TOGGLE_VISIBILITY_OFF') {
    const { subMenuVisible } = action.payload
    return { ...state, subMenuVisible }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default submenu_reducer
