import { forwardRef } from 'react'
import styled from 'styled-components'
import '../../App.css'
import ListContentRow from '../molecules/ListContentRow'

const DashboardList = forwardRef((props, ref) => {
  const { columnWidths, paginatedResult, setFilterPos } = props

  const setListKey = el => {
    for (const [key, value] of Object.entries(el)) {
      if (typeof value === 'string' && key.length > 0) return value
    }
  }

  const listcontentCells = paginatedResult.map((el, i) => {
    let colorVar
    if (i % 2 === 0) colorVar = 'rgba(231,232,233,1.00)'
    else colorVar = 'rgba(255,255,255)'
    const style = { gridRow: `${i + 2}/${i + 3}` }
    const styleBackground = { background: `${colorVar}` }
    const listKey = setListKey(el)
    return (
      <ListContentRow
        content={el}
        key={listKey}
        style={style}
        styleBackground={styleBackground}
        columnWidths={columnWidths}
      />
    )
  })

  const addToRefs = (el, i) => (ref.current[i] = el)

  const onScroll = () => {
    ref.current[0].scrollLeft = ref.current[1].scrollLeft
    if (ref.current[3] !== null) {
      setFilterPos()
    }
  }

  return (
    <Wrapper onScroll={onScroll} ref={el => addToRefs(el, 1)}>
      {listcontentCells}
    </Wrapper>
  )
})

export default DashboardList

const Wrapper = styled.div`
    width: 100%;
    min-height: 250px;
    overflow: scroll;
    display: grid;
    color: #ffffff;
    align-content: start;
  }

  .list-container-header {
    display: grid;
    grid-auto-flow: column;
  }

  .list-header-left {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 3px;
    grid-column-gap: 3px;
    width: 100%;
    background: rgba(68, 67, 66, 1);
    grid-template-rows: 1.375rem 1.375rem;
    grid-column: 1/2;
    grid-row: 1/2;
    grid-column-gap: 6px;
    justify-items: center;
    align-items: center;
    margin-bottom: 6px;
  }
  
  .h5-list {
    transition: all 0.2s;
    justify-self: center;
    align-self: center;
  }

  .h5-list:hover {
    cursor: pointer;
    color: rgba(0, 192, 243, 1);
  }
`
