const list_reducer = (state, action) => {
  if (action.type === 'LOAD_DATA') {
    const optionsVisible = {}
    action.payload.listCells.forEach(el => {
      optionsVisible[el] = false
    })
    return {
      ...state,
      listData: action.payload.listCells,
      optionsVisible
    }
  }
  if (action.type === 'FETCH_LISTDATA_BEGIN')
    return { ...state, isLoading: true }
  if (action.type === 'FETCH_LISTDATA_SUCCESS') {
    const listData = {
      listHeaders: action.payload.listHeaders,
      listRecords: action.payload.listRecords,
      columnWidths: action.payload.columnWidths,
      dropdowns: action.payload.dropdowns
    }
    return {
      ...state,
      listData,
      isLoading: false
    }
  }
  if (action.type === 'FETCH_LISTDATA_ERROR')
    return { ...state, isLoading: false }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default list_reducer
