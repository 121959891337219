const preferences_reducer = (state, action) => {
  if (action.type === 'FETCH_PREFERENCESDATA_BEGIN')
    return { ...state, isLoading: true }
  if (action.type === 'FETCH_PREFERENCESDATA_SUCCESS') {
    return {
      ...state,
      columnWidths: action.payload,
      isLoading: false
    }
  }
  if (action.type === 'FETCH_PREFERENCESDATA_ERROR')
    return { ...state, isLoading: false }
  if (action.type === 'SET_LANGUAGE') {
    return { ...state, currentLanguage: action.payload }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default preferences_reducer
