const footer_reducer = (state, action) => {
  if (action.type === 'LOAD_DATA') {
    return {
      ...state,
      menuItems: action.payload.menuItems,
      versionNumber: action.payload.versionNumber,
      loginName: action.payload.loginName
    }
  }
  if (action.type === 'TOGGLE_FOOTER_MENUS') {
    return { ...state, areFooterMenusVisible: action.payload }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default footer_reducer
