import logo from '../../assets/logoPM.png'
import styled from 'styled-components'

const DashboardMenu = props => {
  const { menuData } = props

  const menuItemsToDisplay = menuData.map(el => (
    <button
      className='menu-admin-button'
      key={el}
      onClick={props.toggleVisibility}
      name={el}
    >
      {el}
    </button>
  ))

  return (
    <Wrapper>
      <a href='/'>
        <img className='menu-logo' src={logo} alt='Plantmarket logo' />
      </a>
      <div className='menu-content'>{menuItemsToDisplay}</div>
      <button className='menu-logout'>Logout</button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(45, 42, 38, 1);
  width: 80px;
  display: grid;
  justify-items: center;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-size: 0.625rem;
  align-content: space-between;
  box-sizing: border-box;

  .menu-logo {
    width: 60px;
  }

  .menu-content {
    display: grid;
    height: max-content;
    width: 100%;
  }

  .menu-admin-button {
    height: 50px;
    font-size: 0.625rem;
    border: none;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.7);

    &:hover {
      cursor: pointer;
      background: rgba(62, 60, 58, 1);
    }
  }

  .menu-logout {
    background-color: rgba(172, 30, 40, 1);
    width: 100%;
    height: 1.875rem;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.2s;
    border: none;
    font-size: 0.625rem;
    text-transform: uppercase;
  }

  .menu-logout:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
  }
`

export default DashboardMenu
