import ListRowIcon from '../atoms/ListRowIcon'
import { RiDeleteBinLine } from 'react-icons/ri'
import styled from 'styled-components'
import { MdCircle } from 'react-icons/md'

const ListContentRow = props => {
  const { content, style, columnWidths, styleBackground } = props

  const cellsToDisplay = Object.values(content).map((el, i) => {
    const styleRowRight = structuredClone(style)
    styleRowRight.width = columnWidths[i]

    return (
      <p
        className='list-row-p'
        style={styleRowRight}
        key={`${Object.values(content).toString()} ${i}`}
      >
        {el}
      </p>
    )
  })

  return (
    <Wrapper style={styleBackground}>
      <div className='list-row-left' style={style}>
        <div></div>
        <ListRowIcon icon={<MdCircle className='icon-square-red' />} />
        <ListRowIcon icon={<RiDeleteBinLine className='icon-square-grey' />} />
      </div>
      {cellsToDisplay}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  height: 1.375rem;

  .list-row-left {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 3px;
    grid-gap: 3px;
    grid-column: 1/2;
    width: 70px;
  }

  .list-row-p {
    padding: 3px;
    color: rgba(45, 42, 38, 1);
    display: flex;
    font-size: 14px;
    white-space: nowrap;
  }
`

export default ListContentRow
