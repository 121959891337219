import styled from 'styled-components'
import ButtonLogin from '../atoms/ButtonLogin'
import LoginInputField from '../atoms/LoginInputField'
import { VscClose } from 'react-icons/vsc'
import FooterIcon from '../atoms/FooterIcon'

const FooterSettings = props => {
  const { style, clicked } = props
  return (
    <Wrapper style={style}>
      <div className='footer-settings-container'>
        <FooterIcon
          clicked={clicked}
          icon={<VscClose className='icon' size={'24px'} />}
        />
        <h3 className='footer-settings-h3'>List settings</h3>
        <hr className='footer-settings-line'></hr>
        <div className='footer-settings-grid'>
          <p className='footer-settings-p grid-row-1 grid-column-1'>Remember</p>
          <div className='footer-settings-buttons grid-row-1 grid-column-2'>
            <ButtonLogin buttonValue='Sort' />
            <ButtonLogin buttonValue='Filter' />
          </div>
          <p className='footer-settings-p grid-row-2 grid-column-1'>Update</p>
          <div className='footer-settings-buttons grid-row-2 grid-column-2'>
            <ButtonLogin buttonValue='Enabled' />
          </div>
          <p className='footer-settings-p grid-row-3 grid-column-3'>
            Update cycle
          </p>
          <div className='footer-settings-buttons grid-row-3 grid-column-3'>
            <ButtonLogin buttonValue='5 sec' />
            <ButtonLogin buttonValue='30 sec' />
            <ButtonLogin buttonValue='1 min' />
            <ButtonLogin buttonValue='3 min' />
          </div>
          <p className='footer-settings-p grid-row-4 grid-column-1'>Records</p>
          <div className='footer-settings-buttons grid-row-4 grid-column-2'>
            <LoginInputField />
          </div>
          <p className='footer-settings-p grid-row-5 grid-column-1'>
            Row copy type
          </p>
          <div className='footer-settings-buttons grid-row-5 grid-column-2'>
            <ButtonLogin buttonValue='CSV' />
            <ButtonLogin buttonValue='CSV Quotes' />
            <ButtonLogin buttonValue='Readable' />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  right: 96px;
  bottom: 32px;
  z-index: 1000;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  border: 1px solid rgba(174, 176, 178, 1);

  .footer-settings-h3 {
    color: rgba(45, 42, 38, 1);
    text-transform: none;
  }

  .footer-settings-p {
    font-size: 14px;
    text-transform: none;
    color: rgba(45, 42, 38, 1);
  }

  .footer-settings-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .footer-settings-line {
    margin-bottom: 6px;
    color: rgba(45, 42, 38, 1);
  }

  .footer-settings-buttons {
    display: flex;
    grid-auto-flow: column;
    grid-gap: 3px;
  }

  .footer-settings-grid {
    display: grid;
    grid-auto-flow: column;
    grid-row-gap: 3px;
  }

  .grid-row-1 {
    grid-row: 1;
  }

  .grid-row-2 {
    grid-row: 2;
  }

  .grid-row-3 {
    grid-row: 3;
  }

  .grid-row-4 {
    grid-row: 4;
  }

  .grid-row-5 {
    grid-row: 5;
  }

  .grid-column-1 {
    grid-column: 1;
    width: 150px;
  }

  .grid-column-2 {
    grid-column: 2;
  }
`

export default FooterSettings
