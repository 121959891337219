import logo from '../../assets/logoPlantMarket.png'
import LanguageBar from '../../components/molecules/LanguageBar'
import styled from 'styled-components'

const LoginHeader = props => {
  const { userType, languageImagesToDisplay } = props

  return (
    <Wrapper>
      <img src={logo} alt='logo' className='login-image' />
      <h1 className='login-h1'>{userType} Login</h1>
      <LanguageBar languageImagesToDisplay={languageImagesToDisplay} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content, max-content, max-content;
  grid-gap: 6px;

  .login-image {
    height: 3.75rem;
  }

  .login-h1 {
    margin: 0px;
    padding: 0px;
  }
`
export default LoginHeader
