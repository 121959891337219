import React, { useContext, useReducer, useEffect } from 'react'
import reducer from '../reducers/footer_reducer'
import dashboardData from '../data/dashboardData'

const initialState = {
  versionNumber: '',
  loadTime: '0.92',
  loginName: '',
  areFooterMenusVisible: [false, false]
}

const FooterContext = React.createContext()

export const FooterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'LOAD_DATA', payload: dashboardData })
  }, [dashboardData])

  const toggleFooterMenus = footerNumber => {
    const areFooterMenusVisible = [false, false]
    areFooterMenusVisible[footerNumber] = !state.areFooterMenusVisible[
      footerNumber
    ]
    dispatch({
      type: 'TOGGLE_FOOTER_MENUS',
      payload: areFooterMenusVisible
    })
  }

  return (
    <FooterContext.Provider
      value={{
        ...state,
        toggleFooterMenus
      }}
    >
      {children}
    </FooterContext.Provider>
  )
}

export const useFooterContext = () => {
  return useContext(FooterContext)
}
