import React, { useContext, useReducer } from 'react'
import reducer from '../reducers/list_reducer'
import listWholesaleData from '../data/listWholesaleData'
import listWholesaleDataFull from '../data/listWholesaleDataFull'
import listRetailData from '../data/listRetailData'
import listRetailDataFull from '../data/listRetailDataFull'

const initialState = {
  listData: {
    listHeaders: [],
    columnsWidths: [],
    listRecords: [],
    dropdowns: []
  },
  isLoading: false
}

const ListContext = React.createContext()

export const ListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const fetchListData = listId => {
    dispatch({ type: 'FETCH_LISTDATA_BEGIN' })
    try {
      const curListData = setListData(listId)
      const listHeaders = setHeaderData(curListData, 'name')
      const columnWidths = setHeaderData(curListData, 'width')
      const dropdowns = setHeaderData(curListData, 'dropdown')
      const listRecords = curListData.listRecords

      dispatch({
        type: 'FETCH_LISTDATA_SUCCESS',
        payload: { listRecords, columnWidths, listHeaders, dropdowns }
      })
    } catch (error) {
      console.log(error)
      dispatch({ type: 'FETCH_LISTDATA_ERROR' })
    }
  }

  const setHeaderData = (listData, valueToSet) => {
    let newHeaderData = []
    listData.listHeaders.forEach(el => {
      newHeaderData.push(el[valueToSet])
    })
    return newHeaderData
  }

  const setListData = listId => {
    if (listId === 'wholesale') return listWholesaleData
    if (listId === 'retail') return listRetailDataFull
  }

  return (
    <ListContext.Provider
      value={{
        ...state,
        fetchListData
      }}
    >
      {children}
    </ListContext.Provider>
  )
}

export const useListContext = () => {
  return useContext(ListContext)
}
