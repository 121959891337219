import styled from 'styled-components'
import '../../App.css'

const FooterIcon = props => {
  const { icon, clicked, dynamicStyle } = props
  return (
    <Wrapper style={dynamicStyle} onClick={e => clicked(e)}>
      {icon}
    </Wrapper>
  )
}

export default FooterIcon

const Wrapper = styled.div`
  color: rgba(161, 160, 164, 1);
  font-size: 14px;
  transition: all 0.3s;
  &:hover {
    color: rgba(0, 192, 243, 1);
    cursor: pointer;
  }
`
