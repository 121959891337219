import styled from 'styled-components'
import '../../App.css'

const ListHeaderIcon = props => {
  return <Wrapper>{props.icon}</Wrapper>
}

export default ListHeaderIcon

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    color: rgba(255, 255, 255, 0.3);
    justify-self: center;
    align-self: center;
    font-size: 16px;
  }

  &:hover {
    background: rgba(150, 38, 44, 1);
    cursor: pointer;
    color: #ffffff;
  }

  &:hover .icon {
    color: #ffffff;
  }
`
