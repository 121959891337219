import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { LoginProvider } from './contexts/login_context'
import { HeaderProvider } from './contexts/header_context'
import { ListProvider } from './contexts/list_context'
import { PreferencesProvider } from './contexts/preferences_context'
import { FilterProvider } from './contexts/filter_context'
import { SortProvider } from './contexts/sort_context'
import { PaginationProvider } from './contexts/pagination_context'
import { SubmenuProvider } from './contexts/submenu_context'
import { FooterProvider } from './contexts/footer_context'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <LoginProvider>
      <HeaderProvider>
        <SubmenuProvider>
          <FooterProvider>
            <ListProvider>
              <PreferencesProvider>
                <FilterProvider>
                  <SortProvider>
                    <PaginationProvider>
                      <App />
                    </PaginationProvider>
                  </SortProvider>
                </FilterProvider>
              </PreferencesProvider>
            </ListProvider>
          </FooterProvider>
        </SubmenuProvider>
      </HeaderProvider>
    </LoginProvider>
  </React.StrictMode>
)
