import React, { useContext, useReducer, useEffect } from 'react'
import reducer from '../reducers/header_reducer'
import dashboardData from '../data/dashboardData'

const initialState = {
  pageTitle: 'Channel Product Retail',
  pageTitleSecondary: 'All channels',
  productSearchInput: '',
  headerDropdownVisible: false,
  headerDropdownValues: [
    'All Products',
    'Deleted Products',
    'No Stock',
    'Only With Stock'
  ],
  headerDropdownSelection: ''
}

const HeaderContext = React.createContext()

export const HeaderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'LOAD_DATA', payload: dashboardData })
  }, [dashboardData])

  const handleChange = e => {
    dispatch({
      type: 'HANDLE_CHANGE',
      stateProperty: e.target.name,
      payload: e.target.value
    })
  }

  const handleChangeHeaderDropdown = (e, headerDropdownSelection) => {
    dispatch({
      type: 'HANDLE_CHANGE_HEADER_DROPDOWN',
      payload: { headerDropdownSelection }
    })
    if (e.target.getAttribute('name') === 'headerInputIcon')
      toggleHeaderDropdown(false)
    else toggleHeaderDropdown(!state.headerDropdownVisible)
    delayFunction(headerDropdownSelection)
  }

  const delayFunction = () => {
    setTimeout(() => {
      console.log('works')
    }, 2000)
  }

  const toggleHeaderDropdown = isHeaderDropdownVisible => {
    dispatch({
      type: 'TOGGLE_HEADER_DROPDOWN',
      payload: isHeaderDropdownVisible
    })
  }

  return (
    <HeaderContext.Provider
      value={{
        ...state,
        handleChange,
        handleChangeHeaderDropdown,
        toggleHeaderDropdown
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export const useHeaderContext = () => {
  return useContext(HeaderContext)
}
