import React, { forwardRef } from 'react'
import styled from 'styled-components'
import '../../App.css'
import ListHeaderCell from '../molecules/ListHeaderCell'
import { TbArrowsDownUp } from 'react-icons/tb'
import { TbTriangleInverted } from 'react-icons/tb'
import { TbFilter } from 'react-icons/tb'
import ListHeaderIcon from '../atoms/ListHeaderIcon'

const ListHeader = forwardRef((props, ref) => {
  const {
    setFilterDropdown,
    listData,
    handleChangeFilters,
    inputValuesFilters,
    setFilterPos
  } = props

  const onScroll = () => {
    ref.current[1].scrollLeft = ref.current[0].scrollLeft
    if (ref.current[3] !== null) {
      setFilterPos()
    }
  }

  const listHeaderCells = listData.listHeaders.map((el, i) => {
    let inputValueFilter = ''
    if (el in inputValuesFilters) inputValueFilter = inputValuesFilters[el]
    return (
      <ListHeaderCell
        title={el}
        name={el}
        key={el}
        dropdown={listData.dropdowns[i]}
        width={listData.columnWidths[i]}
        setFilterDropdown={setFilterDropdown}
        handleChangeFilters={handleChangeFilters}
        inputValueFilter={inputValueFilter}
        setFilterPos={setFilterPos}
        ref={ref}
      />
    )
  })

  const addToRefs = (el, i) => (ref.current[i] = el)

  return (
    <Wrapper ref={el => addToRefs(el, 0)} onScroll={onScroll}>
      <div className='list-header-left'>
        <h5 className='h5-list'>P</h5>
        <h5 className='h5-list'>S</h5>
        <div className='h5-list'></div>
        <ListHeaderIcon icon={<TbFilter className='icon' />} />
        <ListHeaderIcon icon={<TbTriangleInverted className='icon' />} />
        <ListHeaderIcon icon={<TbArrowsDownUp className='icon' />} />
      </div>
      {listHeaderCells}
    </Wrapper>
  )
})

export default ListHeader

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  color: #ffffff;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .list-header-left {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 3px;
    background: rgba(68, 67, 66, 1);
    grid-template-rows: 1.375rem 1.375rem;
    grid-column: 1/2;
    grid-row: 1/2;
    justify-items: center;
    align-items: center;
    width: 70px;
  }

  .h5-list {
    transition: all 0.2s;
    justify-self: center;
    align-self: center;
  }

  .h5-list:hover {
    cursor: pointer;
    color: rgba(0, 192, 243, 1);
  }
`
