import DashboardFooter from '../components/organisms/DashboardFooter'
import DashboardHeader from '../components/organisms/DasboardHeader'
import DashboardMenu from '../components/organisms/DashboardMenu'
import DashboardList from '../components/organisms/DashboardList'
import DashboardSubMenu from '../components/molecules/DashboardSubMenu'
import DashboardAdvFilter from '../components/molecules/DashboardAdvFilter.js'
import ListHeader from '../components/organisms/ListHeader'
import styled from 'styled-components'
import { useHeaderContext } from '../contexts/header_context'
import { useFooterContext } from '../contexts/footer_context'
import { useSubmenuContext } from '../contexts/submenu_context'
import { useListContext } from '../contexts/list_context'
import { useFilterContext } from '../contexts/filter_context'
import { usePaginationContext } from '../contexts/pagination_context'
import ListDropdownFilter from '../components/molecules/ListDropdownFilter'
import { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const DashboardPage = () => {
  const { id } = useParams()

  const {
    pageTitle,
    pageTitleSecondary,
    handleChange,
    toggleHeaderDropdown,
    headerDropdownVisible,
    headerDropdownValues,
    headerDropdownSelection,
    handleChangeHeaderDropdown
  } = useHeaderContext()

  const {
    versionNumber,
    loadTime,
    loginName,
    toggleFooterMenus,
    areFooterMenusVisible
  } = useFooterContext()

  const {
    subMenuVisible,
    toggleVisibility,
    submenuData,
    curSubmenuName
  } = useSubmenuContext()

  const { listData, fetchListData } = useListContext()

  const {
    inputValuesFilters,
    handleChangeFilters,
    filterDropdownVisible,
    setFilterDropdown,
    filterDropdownWidth,
    advFilters,
    isAdvFilterVisible,
    toggleAdvFilters,
    setAdvancedFilters,
    setRouteId,
    filterDropdownValues,
    handleChangeFilterDropdown,
    filterDropdownColumnName,
    filterDropdownSelections
  } = useFilterContext()

  const {
    paginatedResult,
    curPage,
    totalPages,
    pageInputValue,
    toNextPage,
    toPrevPage,
    setCurPage,
    totalRecords
  } = usePaginationContext()

  useEffect(() => {
    setRouteId(id)
    fetchListData(id)
    setAdvancedFilters()
  }, [id])

  //0=headerRef, 1= listContentRef, 2=dropdownRef, 3= headerCellRef
  const refs = useRef([null, null, null, null])

  const menuData = [
    'SYSTEM',
    'MANAGE',
    'ACCOUNT',
    'SUPPLIER',
    'WINTREE',
    'PM',
    'CATALOG',
    'CHANNEL',
    'MAGENTO',
    'API',
    'DEVICE',
    'VARB'
  ]

  const setFilterPos = () => {
    const newStyle = `${refs.current[3].getBoundingClientRect().x - 15}px`
    refs.current[2].style.left = newStyle
  }

  return (
    <Wrapper>
      <DashboardSubMenu
        subMenuVisible={subMenuVisible}
        toggleVisibility={toggleVisibility}
        submenuData={submenuData}
        curSubmenuName={curSubmenuName}
      />
      <DashboardHeader
        pageTitle={pageTitle}
        pageTitleSecondary={pageTitleSecondary}
        handleChange={handleChange}
        toggleAdvFilters={toggleAdvFilters}
        toggleHeaderDropdown={toggleHeaderDropdown}
        headerDropdownVisible={headerDropdownVisible}
        headerDropdownValues={headerDropdownValues}
        headerDropdownSelection={headerDropdownSelection}
        handleChangeHeaderDropdown={handleChangeHeaderDropdown}
      />
      <DashboardAdvFilter
        isAdvFilterVisible={isAdvFilterVisible}
        advFilters={advFilters}
      />
      <DashboardMenu menuData={menuData} toggleVisibility={toggleVisibility} />
      <div className='dashboard-list'>
        <ListHeader
          setFilterDropdown={setFilterDropdown}
          listData={listData}
          inputValuesFilters={inputValuesFilters}
          handleChangeFilters={handleChangeFilters}
          setFilterPos={setFilterPos}
          ref={refs}
        />
        <ListDropdownFilter
          filterDropdownVisible={filterDropdownVisible}
          filterDropdownWidth={filterDropdownWidth}
          filterDropdownValues={filterDropdownValues}
          handleChangeFilterDropdown={handleChangeFilterDropdown}
          filterDropdownColumnName={filterDropdownColumnName}
          filterDropdownSelections={filterDropdownSelections}
          ref={refs}
        />
        <DashboardList
          columnWidths={listData.columnWidths}
          paginatedResult={paginatedResult}
          setFilterPos={setFilterPos}
          ref={refs}
        />
      </div>
      <div className='dashboard-bottom'></div>
      <DashboardFooter
        versionNumber={versionNumber}
        loadTime={loadTime}
        loginName={loginName}
        curPage={curPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        toNextPage={toNextPage}
        toPrevPage={toPrevPage}
        setCurPage={setCurPage}
        pageInputValue={pageInputValue}
        toggleFooterMenus={toggleFooterMenus}
        areFooterMenusVisible={areFooterMenusVisible}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  transition: 0.5s all;
  display: grid;
  grid-row-gap: 6px;
  padding: 0 96px 0 16px;
  height: 100%;
  align-content: start;

  .dashboard-list {
    display: grid;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .dashboard-bottom {
    height: 1.85rem;
  }
`

export default DashboardPage
