import styled from 'styled-components'
import '../../App.css'
import { TbFilter } from 'react-icons/tb'

const ListDropdownIcon = props => {
  return (
    <Wrapper onClick={props.clicked} name={props.name}>
      <TbFilter className='icon-cell' name={props.name} />
    </Wrapper>
  )
}

export default ListDropdownIcon

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

  .icon-cell {
    color: rgba(255, 255, 255, 0.3);
    justify-self: center;
    align-self: center;
    transition: all 0.2s;
  }

  &:hover {
    cursor: pointer;
    color: rgba(0, 192, 243, 1);
    border: 1px solid rgba(0, 192, 243, 1);
  }

  &:hover .icon-cell {
    color: rgba(0, 192, 243, 1);
  }
`
