import styled from 'styled-components'

const LoginInputField = props => {
  const {
    inputType,
    inputPlaceholder,
    handleChange,
    inputValue,
    inputName,
    dynamicStyle
  } = props

  return (
    <Wrapper
      style={dynamicStyle}
      type={inputType}
      placeholder={inputPlaceholder}
      onChange={handleChange}
      value={inputValue}
      name={inputName}
    />
  )
}

const Wrapper = styled.input`
  height: 1.875rem;
  line-height: 1.875rem;
  border: 1px solid rgba(174, 176, 178, 1);
  background-color: rgba(255, 255, 255, 0.96);
  padding: 0 4px;
  letter-spacing: 0.03rem;
  border-radius: 1px;
  outline: none;
  &:focus {
    border: 1px solid rgba(0, 192, 243, 1);
    background-color: rgba(232, 254, 254, 1);
  }
`

export default LoginInputField
