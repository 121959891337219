import styled from 'styled-components'
import ButtonLogin from '../atoms/ButtonLogin'
import { VscClose } from 'react-icons/vsc'
import FooterIcon from '../atoms/FooterIcon'

const FooterExport = props => {
  const { style, clicked } = props
  return (
    <Wrapper style={style}>
      <div className='footer-export-container'>
        <FooterIcon
          clicked={clicked}
          icon={<VscClose className='icon' size={'24px'} />}
        />
        <h3 className='footer-h3'>footer export</h3>
        <hr className='footer-export-line'></hr>
        <div className='footer-export-row'>
          <p className='footer-p'>Export</p>
          <div className='footer-buttons'>
            <ButtonLogin buttonValue='Filtered Data Only' />
            <ButtonLogin buttonValue='Export' />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  right: 96px;
  bottom: 32px;
  z-index: 1000;
  background: rgba(255,255,255, 1.00);
  padding: 10px;
  border: 1px solid rgba(174,176,178,1.00);

  .footer-h3 {
    color: rgba(45, 42, 38, 1);
    text-transform: none;
  }

  .footer-p {
    color: rgba(45,42,38,1.00);
    font-size: 14px;
    text-transform: none;
  }

  .footer-export-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .footer-export-line {
    margin-bottom: 6px;
    color: rgba(45, 42, 38, 1);
  }

  .footer-buttons {
    display: flex;
    grid-auto-flow: column;
    grid-gap: 3px;
  }

  .footer-export-row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 90px;
  }
    }
`

export default FooterExport
