import styled from 'styled-components'
import '../../App.css'
import ListDropdownIcon from '../atoms/ListDropdownIcon'
import { useRef } from 'react'
import { forwardRef } from 'react'

const ListHeaderCell = forwardRef((props, ref) => {
  const {
    width,
    title,
    setFilterDropdown,
    name,
    handleChangeFilters,
    inputValueFilter,
    dropdown,
    setFilterPos
  } = props
  const headerCellRef = useRef(null)

  const toggleFilter = () => {
    ref.current[3] = headerCellRef.current
    setFilterDropdown(title, width)
    setFilterPos()
  }

  const setDropdown = () => {
    if (dropdown)
      return <ListDropdownIcon clicked={e => toggleFilter(e)} name={name} />
  }

  return (
    <Wrapper width={`${width}px`} ref={headerCellRef}>
      <h5 className='h5-list-cell'>{title}</h5>
      <div></div>
      <input
        type='text'
        className='input-list-cell'
        onChange={handleChangeFilters}
        value={inputValueFilter}
        name={title}
        placeholder=''
      />
      {setDropdown()}
    </Wrapper>
  )
})

export default ListHeaderCell

const Wrapper = styled.div`
  position: relative;
  padding: 3px 1px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1.375rem 1.375rem;
  justify-items: center;
  align-items: center;
  grid-column-gap: 1px;
  grid-row: 1/2;
  background: rgba(68, 67, 66, 1);
  width: ${props => props.width};

  .input-list-cell {
    border: 1px solid rgba(255, 255, 255, 0.96);
    background-color: rgba(255, 255, 255, 0.96);
    height: 100%;
    line-height: 1.375rem;
    width: 100%;
    letter-spacing: 0.03rem;
    border-radius: 1px;
    align-self: start;
    outline: none;
  }

  .h5-list-cell {
    justify-self: left;
    transition: all 0.2s;
    align-self: center;
  }

  .h5-list-cell:hover {
    cursor: pointer;
    color: rgba(0, 192, 243, 1);
  }

  .options-container {
    position: absolute;
    left: 0;
    top: 50.5px;
    height: 200px;
    width: 100%;
    background: #ffffff;
    overflow: visible;
    border: 1px solid rgba(106, 115, 123, 0.8);
    padding: 6px;
    z-index: 1000;
  }
`
