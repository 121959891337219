import styled from 'styled-components'
import HeaderInputIcon from './HeaderInputIcon'
import { VscClose } from 'react-icons/vsc'

const HeaderInputField = props => {
  const {
    inputType,
    inputPlaceholder,
    handleChange,
    inputValue,
    inputName,
    dynamicStyle,
    handleChangeHeaderDropdown,
    headerDropdownSelection
  } = props

  const displayFooterIcon = () => {
    if (headerDropdownSelection.length > 0)
      return (
        <HeaderInputIcon
          clicked={handleChangeHeaderDropdown}
          dynamicStyle={{
            position: 'absolute',
            top: '50%',
            right: '5px',
            transform: 'translateY(-50%)'
          }}
          icon={
            <VscClose
              onClick={e => handleChangeHeaderDropdown(e, '')}
              name='headerInputIcon'
            />
          }
        />
      )
    else return null
  }

  return (
    <Wrapper>
      <input
        style={dynamicStyle}
        type={inputType}
        placeholder={inputPlaceholder}
        onChange={handleChange}
        value={inputValue}
        name={inputName}
        onClick={e => handleChangeHeaderDropdown(e, headerDropdownSelection)}
        className='input'
      />
      {displayFooterIcon()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  .input {
    height: 1.875rem;
    line-height: 1.875rem;
    border: 1px solid rgba(174, 176, 178, 1);
    background-color: rgba(255, 255, 255, 0.96);
    padding: 0 4px;
    letter-spacing: 0.03rem;
    border-radius: 1px;
    outline: none;
    &:focus {
      border: 1px solid rgba(0, 192, 243, 1);
      background-color: rgba(232, 254, 254, 1);
    }
  }
`

export default HeaderInputField
