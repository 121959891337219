import styled from 'styled-components'

const ButtonLogin = props => {
  const { buttonValue, buttonOnClick, dynamicStyle } = props

  return (
    <Wrapper
      style={dynamicStyle}
      className='login-button'
      type='button'
      value={buttonValue}
      onClick={buttonOnClick}
    />
  )
}

const Wrapper = styled.input`
  font-size: 14px;
  width: 100%;
  color: rgba(113, 112, 116, 1);
  border: 1px solid rgba(174, 176, 178, 1);
  background-color: rgba(201, 203, 204, 1);
  height: 1.875rem;
  line-height: 1.875rem;
  padding: 0 16px;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(0, 192, 243, 1);
    background-color: rgba(0, 192, 243, 1);
  }
`

export default ButtonLogin
