import LoginInputField from '../atoms/LoginInputField'
import ButtonLogin from '../atoms/ButtonLogin'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ButtonBack from '../atoms/ButtonBack'

const LoginContent = props => {
  const {
    username,
    password,
    handleChange,
    nextLoginStep,
    currentLoginStep,
    prevLoginStep
  } = props

  const buttonStepOne = (
    <ButtonLogin
      buttonValue='Next Step'
      buttonOnClick={() => nextLoginStep(username)}
      justifyRight
      dynamicStyle={{ gridRow: '2', gridColumn: '2' }}
    />
  )

  const buttonsStepThree = (
    <>
      <ButtonBack
        buttonValue='Back'
        buttonOnClick={prevLoginStep}
        justifyCenter={false}
        dynamicStyle={{ gridRow: '3' }}
      />
      <Link to='/dashboard/wholesale' className='login-link'>
        <ButtonLogin
          buttonValue='Login'
          buttonOnClick={() => nextLoginStep(props.password)}
          justifyRight
        />
      </Link>
    </>
  )

  const displayButtons = () => {
    if (currentLoginStep === 1) return buttonStepOne
    return <>{buttonsStepThree}</>
  }

  const displayPasswordInput = () => {
    if (currentLoginStep === 1) return null
    return (
      <LoginInputField
        inputPlaceholder='Enter your password'
        inputValue={password}
        inputName='password'
        handleChange={handleChange}
        inputType='password'
        justifyRight
        dynamicStyle={{ gridRow: '2', gridColumn: '2', width: '100%' }}
      />
    )
  }

  return (
    <Wrapper className='login-grid'>
      <p className='login-p'>name</p>
      <LoginInputField
        inputPlaceholder='Enter your username'
        inputValue={username}
        inputName='username'
        handleChange={handleChange}
        inputType='text'
        dynamicStyle={{ gridRow: '1', gridColumn: '2', width: '100%' }}
      />
      {displayPasswordInput()}
      {displayButtons()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  grid-row-gap: 6px;
  grid-column-gap: 30px;
  margin: 6px 0px 20px 0px;
  align-items: center;
  justify-items: end;
  height: max-content;

  .login-p {
    grid-row: 1;
    grid-column: 1;
    margin: 0;
  }

  .login-link {
    grid-row: 3;
    grid-column: 2;
    width: 100%;
  }
`
export default LoginContent
