const dashboardMenuData = {
  SYSTEM: [
    {
      title: 'Framework',
      items: [
        { name: 'Framework Configuration', id: 'retail' },
        { name: 'Framework Attribute', id: 'retail' },
        { name: 'Framework Logging', id: 'retail' }
      ]
    },
    {
      title: 'System',
      items: [{ name: 'menuLinkSystemReports', id: 'retail' }]
    },
    { title: 'Cron', items: [{ name: 'Cron Lock Summary', id: 'retail' }] },
    { title: 'Locale', items: [{ name: 'Language', id: 'retail' }] },
    {
      title: 'Maintenance',
      items: [
        { name: 'Framework Maintenance', id: 'retail' },
        { name: 'Menu Maintenance', id: 'retail' },
        { name: 'Hash Generate', id: 'retail' },
        { name: 'Hash Replace', id: 'retail' },
        { name: 'QR Code Generator', id: 'retail' }
      ]
    },
    {
      title: 'Database',
      items: [
        { name: 'Database Connection', id: 'retail' },
        { name: 'Database Tables', id: 'retail' },
        { name: 'Database Attribute', id: 'retail' },
        { name: 'Database Table Data', id: 'retail' },
        { name: 'Database Check', id: 'retail' },
        { name: 'Database Upgrade', id: 'retail' }
      ]
    },
    {
      title: 'Network',
      items: [
        { name: 'Network Summary', id: 'retail' },
        { name: 'Network Monitor', id: 'retail' },
        { name: 'Network Attribute', id: 'retail' },
        { name: 'Network Attribute Set', id: 'retail' }
      ]
    },
    {
      title: 'Manual',
      items: [
        { name: 'Manual Configuration *', id: 'retail' },
        { name: 'Manual Attribute *', id: 'retail' },
        { name: 'Manual Attribute Set *', id: 'retail' }
      ]
    }
  ],
  CATALOG: [
    {
      title: 'Batch',
      items: [
        { name: 'Create Batch', id: 'retail' },
        { name: 'Product Batch', id: 'retail' },
        { name: 'Product Batch Modified', id: 'retail' }
      ]
    },
    { title: 'Brand', items: [{ name: 'Brand Summary', id: 'retail' }] },
    {
      title: 'Product',
      items: [
        { name: 'Product Retail', id: 'retail' },
        { name: 'Product Wholesale', id: 'wholesale' },
        { name: 'Product Configuration', id: 'retail' },
        { name: 'Product Group', id: 'retail' },
        { name: 'Product Bundle', id: 'retail' },
        { name: 'Product Media', id: 'retail' },
        { name: 'Product EAN', id: 'retail' }
      ]
    },
    {
      title: 'Attribute',
      items: [
        { name: 'Product Attribute', id: 'retail' },
        { name: 'Product Attribute Set', id: 'retail' }
      ]
    }
  ]
}

export default dashboardMenuData
