import styled from 'styled-components'

const ButtonBack = props => {
  const { buttonValue, buttonOnClick, dynamicStyle, justifyCenter } = props

  return (
    <Wrapper
      style={dynamicStyle}
      justifyCenter={justifyCenter}
      id='backButton_step3'
      type='button'
      value={buttonValue}
      onClick={buttonOnClick}
    />
  )
}

const Wrapper = styled.input`
  width: 110px;
  grid-column: ${props => (props.justifyCenter ? '1/-1' : '1')};
  justify-self: ${props => (props.justifyCenter ? 'center' : 'start')};
  color: rgba(113, 112, 116, 1);
  border: 1px solid rgba(174, 176, 178, 1);
  background-color: rgba(201, 203, 204, 1);
  height: 1.875rem;
  line-height: 1.875rem;
  padding: 0 16px;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(161, 160, 164, 1);
    background-color: rgba(161, 160, 164, 1);
  }
`

export default ButtonBack
