import styled from 'styled-components'
import '../../App.css'
import { forwardRef } from 'react'

const ListDropdownFilter = forwardRef((props, ref) => {
  const {
    filterDropdownVisible,
    filterDropdownWidth,
    filterDropdownValues,
    handleChangeFilterDropdown,
    filterDropdownColumnName,
    filterDropdownSelections
  } = props

  const dropdownRows = filterDropdownValues.map((el, i) => {
    return (
      <div
        key={el}
        className='filter-dropdown-row'
        onClick={() => handleChangeFilterDropdown(filterDropdownColumnName, el)}
      >
        <input
          type='checkbox'
          className='filter-dropdown-checkbox'
          checked={filterDropdownSelections[i]}
        />
        <p className='filter-dropdown-font-size'>{el}</p>
      </div>
    )
  })

  const addToRefs = (el, i) => (ref.current[i] = el)

  return (
    <Wrapper
      style={{ left: '200px' }}
      filterDropdownVisible={filterDropdownVisible}
      filterDropdownWidth={filterDropdownWidth}
      ref={el => addToRefs(el, 2)}
    >
      {dropdownRows}
    </Wrapper>
  )
})

export default ListDropdownFilter

const Wrapper = styled.div`
  position: absolute;
  top: 2.88rem;
  height: 200px;
  width: 100%;
  width: ${props => props.filterDropdownWidth}px;
  background: #ffffff;
  overflow-y: scroll;
  border: 1px solid rgba(106, 115, 123, 0.8);
  padding: 6px;
  z-index: 100;
  display: ${props => (props.filterDropdownVisible ? '' : 'none')};

  .filter-dropdown-row {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 6px;
    transition: all 0.3s;
    width: 100%;
    &:hover {
      cursor: pointer;
      background: rgba(0, 192, 243, 1);
    }

    .filter-dropdown-font-size {
      font-size: 15px;
    }
  }

  input[type='checkbox'] {
    //appearance: none;
    display: grid;
    place-content: center;
    transition: all 0.3s;
    height: 16px;
    width: 16px;
    border-radius: 0px;
    &:hover {
      cursor: pointer;
    }
  }
  input[type='checkbox']:checked {
    transform: scale(1);
  }
`
