import { useLoginContext } from '../contexts/login_context'
import LoginHeader from '../components/organisms/LoginHeader'
import styled from 'styled-components'
import LoginStepOne from '../components/molecules/LoginContent'
import LoginStepTwo from '../components/molecules/LoginQR'

const Login = () => {
  const {
    userType,
    currentLoginStep,
    username,
    password,
    handleChange,
    nextLoginStep,
    prevLoginStep
  } = useLoginContext()

  const displayLoginContent = () => {
    if (currentLoginStep === 1 || currentLoginStep === 3)
      return (
        <LoginStepOne
          username={username}
          handleChange={handleChange}
          prevLoginStep={prevLoginStep}
          nextLoginStep={nextLoginStep}
          password={password}
          currentLoginStep={currentLoginStep}
        />
      )
    if (currentLoginStep === 2)
      return (
        <LoginStepTwo
          username={username}
          handleChange={handleChange}
          nextLoginStep={nextLoginStep}
          prevLoginStep={prevLoginStep}
        />
      )
  }

  return (
    <Wrapper>
      <div className='login-container'>
        <LoginHeader userType={userType} />
        {displayLoginContent()}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  width: 100wh;

  .login-container {
    width: 500px;
    justify-self: center;
    align-self: center;
    padding: 8px;
    box-shadow: 3px 3px 6px 0px rgb(231 232 233);
  }
`

export default Login
