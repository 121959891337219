import de_AT from '../assets/language/de_AT.png'
import de_CH from '../assets/language/de_CH.png'
import de_DE from '../assets/language/de_DE.png'
import en_AU from '../assets/language/en_AU.png'
import en_GB from '../assets/language/en_GB.png'
import es_AR from '../assets/language/es_AR.png'
import es_CL from '../assets/language/es_CL.png'
import es_CO from '../assets/language/es_CO.png'
import es_CR from '../assets/language/es_CR.png'
import es_ES from '../assets/language/es_ES.png'
import fr_CA from '../assets/language/fr_CA.png'
import fr_FR from '../assets/language/fr_FR.png'
import hy_AM from '../assets/language/hy_AM.png'
import it_CH from '../assets/language/it_CH.png'
import it_IT from '../assets/language/it_IT.png'
import ja_JP from '../assets/language/ja_JP.png'
import nl_NL from '../assets/language/nl_NL.png'
import NONE from '../assets/language/NONE.png'
import pl_PL from '../assets/language/pl_PL.png'
import pt_BR from '../assets/language/pt_BR.png'
import pt_PT from '../assets/language/pt_PT.png'
import ro_RO from '../assets/language/ro_RO.png'
import ru_RU from '../assets/language/ru_RU.png'
import sq_AL from '../assets/language/sq_AL.png'
import uk_UA from '../assets/language/uk_UA.png'
import zh_CN from '../assets/language/zh_CN.png'

const imageSrcs = {
  de_AT,
  de_CH,
  de_DE,
  en_AU,
  es_AR,
  es_CL,
  es_CO,
  es_CR,
  es_ES,
  fr_CA,
  fr_FR,
  hy_AM,
  it_CH,
  it_IT,
  ja_JP,
  nl_NL,
  NONE,
  pl_PL,
  pt_BR,
  pt_PT,
  ro_RO,
  ru_RU,
  sq_AL,
  uk_UA,
  zh_CN,
  en_GB
}

export default imageSrcs
