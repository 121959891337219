const login_reducer = (state, action) => {
  if (action.type === 'NEXT_LOGIN_STEP') {
    return { ...state, currentLoginStep: action.payload }
  }
  if (action.type === 'PREVIOUS_LOGIN_STEP') {
    return { ...state, currentLoginStep: action.payload }
  }
  if (action.type === 'HANDLE_CHANGE') {
    return { ...state, [action.stateProperty]: action.payload }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default login_reducer
