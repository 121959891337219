import LanguageBar from '../molecules/LanguageBar'
import styled from 'styled-components'
import FooterExport from '../molecules/FooterExport'
import FooterSettings from '../molecules/FooterSettings'
import FooterIcon from '../atoms/FooterIcon'
import { TiCog } from 'react-icons/ti'
import { TbList } from 'react-icons/tb'
import { TbInfoCircle } from 'react-icons/tb'
import { VscTriangleRight } from 'react-icons/vsc'
import { VscTriangleLeft } from 'react-icons/vsc'

const DashboardFooter = props => {
  const {
    toPrevPage,
    toNextPage,
    setCurPage,
    pageInputValue,
    curPage,
    totalPages,
    loadTime,
    loginName,
    totalRecords,
    toggleFooterMenus,
    areFooterMenusVisible,
    versionNumber
  } = props

  const styleDisplayNone = areFooterMenusVisible.map(el => {
    if (el === true) return {}
    return { display: `none` }
  })

  return (
    <Wrapper>
      <FooterExport
        style={styleDisplayNone[0]}
        clicked={() => toggleFooterMenus(0)}
      />
      <FooterSettings
        style={styleDisplayNone[1]}
        clicked={() => toggleFooterMenus(1)}
      />
      <div className='footer-container'>
        <LanguageBar />
        <div className='p-line'>|</div>
        <p>{versionNumber}</p>
        <div className='footer-text'>|</div>
        <FooterIcon
          icon={<TbInfoCircle className='footer-icon' size={'14px'} />}
        />
        <div className='p-line'>|</div>
        <p>load time</p>
        <p className='p-bold'>{`${loadTime}`} SEC</p>
        <div className='p-line'>|</div>
        <p>logged in</p>
        <p className='p-bold'>{`${loginName}`} </p>
        <div className='p-line'>|</div>
        <FooterIcon
          icon={<TbInfoCircle className='footer-icon' size={'14px'} />}
        />
      </div>

      <div className='footer-container justify-end'>
        <p className='p-bold'>{`${totalRecords}`} </p>
        <p>records </p>
        <div className='p-line'>|</div>
        <p>page</p>
        <p className='p-bold'>{`${curPage} / ${totalPages}`}</p>
        <input
          type='text'
          className='footer-input'
          value={pageInputValue}
          onChange={setCurPage}
        ></input>
        <FooterIcon
          clicked={() => toPrevPage(curPage)}
          icon={<VscTriangleLeft className='footer-icon' size={'20px'} />}
        />
        <FooterIcon
          clicked={() => toNextPage(curPage)}
          icon={<VscTriangleRight className='footer-icon' size={'20px'} />}
        />
        <FooterIcon
          clicked={() => toggleFooterMenus(0)}
          icon={<TbList className='footer-icon' size={'20px'} />}
        />
        <FooterIcon
          clicked={() => toggleFooterMenus(1)}
          icon={<TiCog className='footer-icon' size={'24px'} />}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  z-index: 190;
  background: rgba(231, 232, 233, 1);
  bottom: 0rem;
  left: 0;
  padding: 0px 16px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  color: rgba(161, 160, 164, 1);
  text-transform: uppercase;
  font-size: 0.6rem;
  height: 1.875rem;

  p {
    padding: 0;
    margin: 0;
  }

  .p-bold {
    font-weight: 600;
  }

  .p-line {
    font-size: 0.625rem;
    text-transform: uppercase;
    color: rgba(161, 160, 164, 1);
  }

  .footer-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: 6px;
    align-items: center;
  }

  .justify-end {
    justify-self: end;
    margin-right: 76px;
  }

  .footer-input {
    width: 40px;
    height: 1.25rem;
    line-height: 1.25rem;
    font-size: 0.75rem;
    text-align: center;
    border: 1px solid rgba(174, 176, 178, 1);
    background-color: rgba(255, 255, 255, 0.96);
  }

  .footer-icon {
    font-size: 1rem;
    color: rgba(161, 160, 164, 1);
    transition: all 0.2s;
    display: flex;
    align-self: center;
    &:hover {
      color: rgba(0, 192, 243, 1);
      cursor: pointer;
    }
  }
`

export default DashboardFooter
