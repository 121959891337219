import styled from 'styled-components'
import LoginInputField from '../atoms/LoginInputField'
import ButtonLogin from '../atoms/ButtonLogin'
import HeaderDropdown from '../molecules/HeaderDropdown'
import HeaderInputField from '../atoms/HeaderInputField'

const DashboardHeader = props => {
  const {
    pageTitle,
    pageTitleSecondary,
    handleChange,
    productSearchInput,
    toggleAdvFilters,
    headerDropdownVisible,
    headerDropdownValues,
    headerDropdownSelection,
    handleChangeHeaderDropdown
  } = props

  return (
    <Wrapper>
      <h1 className='color-black'>{pageTitle}</h1>
      <h1 className='justify-right'>{pageTitleSecondary}</h1>
      <div className='header-action'>
        <div className='header-actions-left'>
          <HeaderInputField
            inputPlaceholder='Select website'
            inputValue={headerDropdownSelection}
            inputName='websiteSearchInput'
            handleChange={handleChange}
            inputType='text'
            handleChangeHeaderDropdown={handleChangeHeaderDropdown}
            headerDropdownSelection={headerDropdownSelection}
          />
          <LoginInputField
            inputPlaceholder='Inactive products'
            inputValue={productSearchInput}
            inputName='productSearchInput'
            handleChange={handleChange}
            inputType='text'
          />
          <ButtonLogin
            buttonValue='Advanced Select'
            buttonOnClick={toggleAdvFilters}
          />
          <ButtonLogin buttonValue='Export' />
          <ButtonLogin buttonValue='Import' />
          <HeaderDropdown
            headerDropdownVisible={headerDropdownVisible}
            headerDropdownValues={headerDropdownValues}
            headerDropdownSelection={headerDropdownSelection}
            handleChangeHeaderDropdown={handleChangeHeaderDropdown}
          />
        </div>
        <div className='header-actions-right'>
          <ButtonLogin buttonValue='Undo Selection' />
          <ButtonLogin buttonValue='Save Selection' />
        </div>
      </div>
      <div>
        <div className='header-actions-left'></div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 2.5rem 2.5rem;
  justify-content: space-between;
  height: 100%;
  position: relative;
  align-items: center;

  .header-action {
    border-top: 1px solid rgba(216, 217, 219, 1);
    border-bottom: 1px solid rgba(216, 217, 219, 1);
    background-color: rgba(231, 232, 233, 1);
    height: 100%;
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    padding: 0px 6px;
    justify-content: space-between;
  }

  .header-actions-left {
    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-column-gap: 6px;
    position: relative;
  }

  .header-actions-right {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 6px;
  }

  .justify-right {
    justify-self: right;
  }
`

export default DashboardHeader
