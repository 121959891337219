const dataPreferences = {
  columnWidths: {
    abbrSupplierSkuWholesale: 60,
    ean: 60,
    abbrBatchNumber: 60,
    abbrSupplierNumber: 60,
    nameSearch: 60,
    name: 60,
    rangeIdentifier: 60,
    supplierSizeCode: 60,
    plantGroup: 60,
    abbrStock: 60,
    abbrOrderMinimum: 60,
    abbrOrderIncrement: 60,
    prcp: 60,
    abbrCurrency: 60,
    tax: 60,
    abbrImage: 60,
    imageCore: 60,
    imageRange: 60,
    imageBatch: 60
  }
}

export default dataPreferences
