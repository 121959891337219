const filter_reducer = (state, action) => {
  if (action.type === 'HANDLE_CHANGE') {
    const inputValuesFilters = state.inputValuesFilters
    inputValuesFilters[action.stateProperty] = action.payload
    return { ...state, inputValuesFilters }
  }
  if (action.type === 'SET_INPUT_VALUES_FILTERS') {
    return { ...state, inputValuesFilters: action.payload }
  }
  if (action.type === 'SET_FILTERED_RESULT') {
    return { ...state, filteredResult: action.payload }
  }
  if (action.type === 'SET_FILTER_DROPDOWN') {
    const {
      filterDropdownVisible,
      filterDropdownWidth,
      filterDropdownValues,
      filterDropdownColumnName,
      filterDropdownSelections
    } = action.payload
    return {
      ...state,
      filterDropdownVisible,
      filterDropdownWidth,
      filterDropdownValues,
      filterDropdownColumnName,
      filterDropdownSelections
    }
  }
  if (action.type === 'SET_ADV_FILTERS')
    return {
      ...state,
      advFilters: action.payload
    }
  if (action.type === 'TOGGLE_ADV_FILTERS')
    return {
      ...state,
      isAdvFilterVisible: action.payload
    }
  if (action.type === 'SET_ROUTE_ID')
    return {
      ...state,
      curListId: action.payload
    }
  if (action.type === 'HANDLE_CHANGE_FILTER_DROPDOWN') {
    const { filterDropdownSelections, columnName } = action.payload
    return {
      ...state,
      filterDropdownSelections,
      columnName
    }
  }
  throw new Error(`No Matching "${action.type}" - action type`)
}

export default filter_reducer
