import styled from 'styled-components'
import LoginInputField from './LoginInputField'

const FilterElement = props => {
  return (
    <Wrapper>
      <p>{props.title}</p>
      <LoginInputField />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  grid-gap: 6px;

  .filter-input {
  }
`

export default FilterElement
