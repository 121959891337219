const listMappings = {
  setProductWholesaleContent: (listRecords, j) => {
    return [
      listRecords[j].sku,
      '',
      listRecords[j].batchNumber,
      listRecords[j].btch_manufacturer,
      listRecords[j].name_search,
      listRecords[j].name,
      listRecords[j].rng_range_identifier,
      listRecords[j].sppl_size_code,
      '',
      listRecords[j].sppl_stock_available,
      '',
      '',
      listRecords[j].sppl_prcp,
      listRecords[j].currency,
      listRecords[j].sppl_tax_class,
      listRecords[j].imageCoreTotal
    ]
  },
  setProductRetailContent: (listRecords, j) => {
    return [
      listRecords[j].sku,
      listRecords[j].core_name,
      listRecords[j].sppl_ean,
      '',
      listRecords[j].rtl_batch_array_total,
      listRecords[j].rtl_batch_array,
      listRecords[j].rtl_batch_array,
      listRecords[j].name_search,
      listRecords[j].name,
      '',
      listRecords[j].productGroupHash,
      listRecords[j].productGroupHash,
      '',
      listRecords[j].chnn_stock_retail,
      listRecords[j].chnn_stock_retail,
      '',
      '',
      listRecords[j].sppl_prcp,
      listRecords[j].currency,
      listRecords[j].sppl_tax_class,
      '',
      '',
      listRecords[j].imageBatch
    ]
  }
}

export default listMappings
